import { ComponentType, Fragment } from 'react';
import Header from './Header';
import OnboardingActivateYourAccount from './OnboardingActivateYourAccount';
import OnboardingActivateYourAccountPrepaid from './OnboardingActivateYourAccountPrepaid';
import OnboardingAppAdminDownloadStep, {
  OnboardingAppAdminDownloadUpsell,
} from './OnboardingAppAdminDownloadStep';
import OnboardingAppNotifStep, { OnboardingAppNotifUpsell } from './OnboardingAppNotifStep';
import OnboardingBankStep, { OnboardingBankUpsell } from './OnboardingBankStep';
import OnboardingCompletedStep from './OnboardingCompletedStep';
import OnboardingControlsAndLimits from './OnboardingControlsAndLimits';
import OnboardingHowCanDriversMaximizeSavings from './OnboardingHowCanDriversMaximizeSavings';
import OnboardingHowToMaximizeDiscounts from './OnboardingHowToMaximizeDiscounts';
import OnboardingHowToUseMyAtoBCards from './OnboardingHowToUseMyAtoBCards';
import OnboardingInviteDrivers from './OnboardingInviteDrivers';
import OnboardingInviteDriversStep, {
  OnboardingInviteDriversUpsell,
} from './OnboardingInviteDriversStep';
import OnboardingManageSpendWrapper from './OnboardingManageSpendWrapper';
import OnboardingSecurityDeposit from './OnboardingSecurityDeposit';
import OnboardingSetupFeeStep, { OnboardingSetupFeeUpsell } from './OnboardingSetupFeeStep';
import OnboardingTelematicsStep, { OnboardingTelematicsUpsell } from './OnboardingTelematicsStep';
import OnboardingWelcomeStep from './OnboardingWelcomeStep';
import OnboardingWelcomeToAtoB from './OnboardingWelcomeToAtoB';
import OnboardingWhatCanIDoWithMyAtoBAccount from './OnboardingWhatCanIDoWithMyAtoBAccount';
import OnboardingWhereCanIGetDiscounts from './OnboardingWhereCanIGetDiscounts';

export type OnboardingStep = {
  stepComponent: ComponentType;
  upsellComponent: ComponentType;
  hideProgress?: true;
  blurb: string | null;
};

export type NewOnboardingStep = {
  header: ComponentType;
  rightContent: ComponentType;
  hideStepper: boolean;
};

export const STEP_MAP: { [key: string]: OnboardingStep } = {
  welcome: {
    stepComponent: OnboardingWelcomeStep,
    upsellComponent: Fragment,
    hideProgress: true,
    blurb: null,
  },
  security_deposit: {
    stepComponent: OnboardingSecurityDeposit,
    upsellComponent: Fragment,
    blurb: 'Activate your credit line',
  },
  setup_fee: {
    stepComponent: OnboardingSetupFeeStep,
    upsellComponent: OnboardingSetupFeeUpsell,
    blurb: 'Get your cards shipped',
  },
  bank_account: {
    stepComponent: OnboardingBankStep,
    upsellComponent: OnboardingBankUpsell,
    blurb: 'Connect a bank account',
  },
  telematics: {
    stepComponent: OnboardingTelematicsStep,
    upsellComponent: OnboardingTelematicsUpsell,
    blurb: 'Sync your telematics',
  },
  app_notification: {
    stepComponent: OnboardingAppNotifStep,
    upsellComponent: OnboardingAppNotifUpsell,
    blurb: 'Setup discount notifications',
  },
  app_download: {
    stepComponent: OnboardingAppAdminDownloadStep,
    upsellComponent: OnboardingAppAdminDownloadUpsell,
    blurb: 'Download the AtoB App',
  },
  invite_drivers: {
    stepComponent: OnboardingInviteDriversStep,
    upsellComponent: OnboardingInviteDriversUpsell,
    blurb: 'Invite your drivers',
  },
  complete_onboarding: {
    stepComponent: OnboardingCompletedStep,
    upsellComponent: Fragment,
    blurb: null,
    hideProgress: true,
  },
} as const;

export const NEW_STEP_MAP: { [key: string]: NewOnboardingStep } = {
  new_welcome: {
    header: Fragment,
    rightContent: OnboardingWelcomeToAtoB,
    hideStepper: true,
  },
  new_what_can_i_do_with_my_atob_account: {
    header: Header,
    rightContent: OnboardingWhatCanIDoWithMyAtoBAccount,
    hideStepper: false,
  },
  new_activate_your_account: {
    header: Header,
    rightContent: OnboardingActivateYourAccount,
    hideStepper: false,
  },
  new_activate_your_account_prepaid: {
    header: Header,
    rightContent: OnboardingActivateYourAccountPrepaid,
    hideStepper: false,
  },
  new_how_to_use_my_atob_cards: {
    header: Header,
    rightContent: OnboardingHowToUseMyAtoBCards,
    hideStepper: false,
  },
  new_what_controls_and_limits_can_i_set_on_my_cards: {
    header: Header,
    rightContent: OnboardingControlsAndLimits,
    hideStepper: false,
  },
  new_how_do_i_track_and_manage_my_fleets_spend: {
    header: Header,
    rightContent: OnboardingManageSpendWrapper,
    hideStepper: false,
  },
  new_where_can_i_get_discounts_with_my_atob_card: {
    header: Header,
    rightContent: OnboardingWhereCanIGetDiscounts,
    hideStepper: false,
  },
  new_how_to_maximize_my_discounts: {
    header: Header,
    rightContent: OnboardingHowToMaximizeDiscounts,
    hideStepper: false,
  },
  new_how_can_i_make_sure_my_drivers_maximize_savings: {
    header: Header,
    rightContent: OnboardingHowCanDriversMaximizeSavings,
    hideStepper: false,
  },
  new_invite_drivers_to_use_the_fuel_map: {
    header: Header,
    rightContent: OnboardingInviteDrivers,
    hideStepper: false,
  },
} as const;
