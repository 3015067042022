import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function Failure({ title, text }: { title: string; text: string }): ReactElement {
  return (
    <div className="flex flex-col items-center p-6">
      <div className="mb-3 text-red-600">
        <FontAwesomeIcon className="fa-4x" icon={faWarning} />
      </div>
      <h1 className="mb-4 text-xl font-bold">{title}</h1>
      <p className="text-center text-base">{text}</p>
    </div>
  );
}
