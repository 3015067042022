import useChannelPartner from '@app/hooks/useChannelPartner';
import { ModalBodyContent, ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { faCircleExclamation, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const SecurityDepositPaymentFailed = ({
  onClose,
}: {
  onClose: () => void;
}): ReactElement => {
  const { supportPhoneNumber, supportEmailAddress } = useChannelPartner();

  return (
    <>
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-8 text-red-600">
            <FontAwesomeIcon className="fa-2x" icon={faCircleExclamation} />
          </div>
          <h1 className="mb-2 text-center text-2xl font-bold">Payment Failed</h1>
        </div>
        <div className="mt-6 rounded-lg bg-gray-100 p-4">
          <div className="flex justify-between">
            <FontAwesomeIcon className="fa-lg p-4" icon={faCircleInfo} />
            <p className="w-full px-4 text-base font-medium">
              Please reach out to support to get this resolved. You can call us at{' '}
              {supportPhoneNumber} or email {supportEmailAddress}.
            </p>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="w-full" color="secondary" size="medium" onClick={onClose}>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};
