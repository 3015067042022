import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import useSecurityDeposit from '@app/hooks/useSecurityDeposit';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Slider } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { ModalOptions } from '../ModalOptions/ModalOptions';
import { SecurityDepositOfferDetails } from '../SecurityDepositModals/SecurityDepositOfferDetails';
import { SecurityDepositPaymentPending } from '../SecurityDepositModals/SecurityDepositPaymentPending';

export const SecurityDepositNetNewModal = ({
  open,
  toggleModal,
  offerDetails,
  refetchOfferDetails,
}: {
  open: boolean;
  toggleModal: () => void;
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
}): ReactElement => {
  const {
    securityDepositModalOpen,
    setSecurityDepositModalOpen,
    fixedOffer,
    desiredCreditLine,
    setDesiredCreditLine,
    options,
    selectedOption,
    setSelectedOption,
    isLoading,
    handleContinue,
  } = useSecurityDeposit({ offerDetails, refetchOfferDetails });

  if (
    offerDetails.existing_deposit?.status === 'deposit_pending' ||
    offerDetails.existing_deposit?.status === 'deposit_paid_pending_hold'
  ) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <SecurityDepositPaymentPending
          securityDeposit={offerDetails.existing_deposit}
          onClose={toggleModal}
        />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
          toggleModal();
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={offerDetails.existing_deposit || null}
          onClose={() => {
            setSecurityDepositModalOpen(false);
            toggleModal();
          }}
        />
      </Modal>
      <Modal open={open} toggle={toggleModal}>
        <ModalHeader title="Activate your Credit Line" onClose={toggleModal} />
        <ModalBodyContent overflowVisible={true}>
          <h2 className="mb-2">
            {offerDetails.offer_open
              ? fixedOffer
                ? 'See your offer details below'
                : 'Select your desired credit limit below'
              : 'Your cards have been shipped. Pay the deposit to activate your credit line.'}
          </h2>
          {!fixedOffer && (
            <Slider
              className="mb-4"
              value={desiredCreditLine}
              onChange={(_, value) => setDesiredCreditLine(value as number)}
              marks={[
                { value: 500, label: '$500' }, // add extra mark at $500
                ...Array.from(
                  { length: (offerDetails.max_credit_limit || 4000) / 1000 + 1 },
                  (_, index) => ({
                    value: index * 1000,
                    label: `$${index * 1000}`,
                  }),
                ),
              ]}
              min={0}
              max={offerDetails.max_credit_limit || 4000}
              step={null}
              valueLabelFormat={(value) => `$${value}`}
            />
          )}
          <div className="mt-4 flex flex-col text-base text-gray-700">
            <ModalOptions
              options={options}
              selectedOptionId={selectedOption}
              setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
            />
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <LoadingButton
            loading={isLoading}
            className="flex-1"
            color="primary"
            disabled={desiredCreditLine === 0}
            onClick={handleContinue}
          >
            {offerDetails.offer_open ? 'Accept offer and ship my cards!' : 'Continue'}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
