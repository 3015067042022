import { getEnvironment } from '@app/utils/environment';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { capitalize } from 'lodash-es';
import { SetStateAction } from 'react';
import {
  computeMaxAmountWithFee,
  FeeConfiguration,
  formatAmountString,
  moneyFormat,
  parseAmountString,
  PaymentMethodType,
} from '../utils';

const { VITE_CHECKBOOK_SINGLE_TRANSACTION_LIMIT } = getEnvironment();

const limitPerTransaction: Record<PaymentMethodType, number | null> = {
  ach: null,
  debit: VITE_CHECKBOOK_SINGLE_TRANSACTION_LIMIT || 10000,
  us_domestic_wire: null,
  zelle: VITE_CHECKBOOK_SINGLE_TRANSACTION_LIMIT || 10000,
  paypal: VITE_CHECKBOOK_SINGLE_TRANSACTION_LIMIT || 10000,
  venmo: VITE_CHECKBOOK_SINGLE_TRANSACTION_LIMIT || 10000,
};

export const AmountInput = ({
  amount,
  setAmount,
  walletBalance,
  feeConfiguration,
  setValidAmount,
  minimumDeposit,
  validAmount,
}: {
  amount: string;
  setAmount: (value: SetStateAction<string>) => void;
  walletBalance?: string | null;
  feeConfiguration?: FeeConfiguration;
  minimumDeposit?: string;
  setValidAmount: (value: SetStateAction<boolean>) => void;
  validAmount: boolean;
}) => {
  const walletBalanceAmount = parseAmountString(walletBalance || '0');
  const requestedAmount = parseAmountString(amount);
  const minimumDepositAmount = parseAmountString(minimumDeposit || '0');
  const maxAmountWithFee = computeMaxAmountWithFee({
    amountRequested: walletBalanceAmount * 100,
    feeConfiguration,
  });

  let maxAmount: number;

  const transferLimit = feeConfiguration && limitPerTransaction[feeConfiguration.type];
  if (transferLimit && maxAmountWithFee > transferLimit) {
    maxAmount = transferLimit;
  } else {
    maxAmount = maxAmountWithFee;
  }

  const maxError = feeConfiguration && walletBalance && !validAmount;
  const minError = minimumDeposit && !validAmount;
  const zeroAmount = feeConfiguration && amount != '' && requestedAmount <= 0;

  return (
    <div>
      <h4 className="mb-2 font-medium">Amount</h4>
      <div className="flex flex-col gap-2">
        <TextField
          aria-errormessage='{"type":"error","message":"Amount is required"}'
          error={!validAmount || zeroAmount}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            const formattedAmount = formatAmountString(e.target.value);
            if (feeConfiguration && walletBalance) {
              setValidAmount(
                parseAmountString(formattedAmount) <= maxAmount &&
                  formattedAmount != '' &&
                  parseAmountString(formattedAmount) > 0,
              );
            }
            if (minimumDeposit)
              setValidAmount(parseAmountString(formattedAmount) >= minimumDepositAmount);
            setAmount(formattedAmount);
          }}
          value={amount}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
                </InputAdornment>
              ),
            },
            htmlInput: {
              inputMode: 'decimal',
            },
          }}
          placeholder="0.00"
          helperText={
            (feeConfiguration &&
              amount != '' &&
              requestedAmount <= 0 &&
              'This amount must be greater than $0.00. Please pick a higher amount.') ||
            (maxError &&
              'This amount exceeds the maximum transferable amount. Please pick a lower amount.') ||
            (minError &&
              'This amount is lower than minimum transferable amount. Please pick a higher amount.')
          }
        />
        {feeConfiguration && walletBalance && (
          <InputHelperText
            extremaType="max"
            onClick={() => {
              setAmount(maxAmount.toString());
              setValidAmount(true);
            }}
            amount={maxAmount}
          />
        )}
        {minimumDeposit && (
          <InputHelperText
            extremaType="min"
            amount={minimumDepositAmount}
            onClick={() => {
              setAmount(minimumDepositAmount.toString());
              setValidAmount(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

const InputHelperText = ({
  amount,
  onClick,
  extremaType,
}: {
  amount: number;
  onClick: () => void;
  extremaType: 'min' | 'max';
}) => {
  return (
    <div className="text-secondary text-sm">
      {capitalize(extremaType)} transferable amount:{' '}
      <button className="underline" onClick={onClick}>
        {moneyFormat(amount)}
      </button>
    </div>
  );
};
