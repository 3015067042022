import { useUnlimitedToFlexPromoQuery } from '@app/hooks/query/useUnlimitedToFlexPromoQuery';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Plaid from '../Plaid';

export default function PrepaidUnlimitedToFlexBanner(): ReactElement | null {
  const { data: payload, error, isLoading } = useUnlimitedToFlexPromoQuery();
  const { addToast } = useToasts();
  const { isSmallScreen } = useWindowWidth();
  const navigate = useNavigate();

  const data = payload?.data;

  if (!data || isLoading || error) {
    return null;
  }

  const { amount_to_charge, credit_line_amount } = data;

  return (
    <>
      <div>
        <div
          className={classNames(
            'bg-atob-green',
            'relative flex w-full items-center justify-between overflow-hidden bg-no-repeat',
          )}
        >
          <div className="flex flex-row justify-between gap-x-2 space-x-2 px-[32px] py-[22px] text-base text-gray-100 md:w-full">
            <div className="space-y-2">
              {credit_line_amount ? (
                <div>
                  <b className="font-bold">Congrats! </b>
                  You will be converted to Flex (Credit) for a{' '}
                  {getCurrencyFromCents(credit_line_amount.cents)}
                  credit line after linking your primary bank account.
                </div>
              ) : (
                <div>
                  Please link a primary bank account to be considered for Flex (Credit) with a
                  credit line.
                </div>
              )}
              {amount_to_charge && (
                <div>
                  Please note that you will be billed a{' '}
                  {getCurrencyFromCents(amount_to_charge.cents)} fee on your first statement upon
                  converting to Flex (Credit).
                </div>
              )}
            </div>
            <div className="flex-col self-center">
              <Plaid
                setError={() =>
                  addToast(
                    'There was an error connecting to your bank account. Please contact support for assistance.',
                    {
                      appearance: 'error',
                    },
                  )
                }
                buttonProps={{
                  className: 'px-4 py-3 rounded-full text-sm font-medium',
                  primary: true,
                }}
                text={isSmallScreen ? 'Connect' : 'Connect Bank Account'}
                onSuccessCallback={() => navigate('/billing/payment-methods')}
                onLinkingCallback={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
