import EntryButton from '@app/components/EntryButton/EntryButton';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faArrowRightArrowLeft, faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadioGroup } from '@mui/material';
import classNames from 'classnames';
import { TransferKind } from '../utils';
import { useWalletTransfer, WalletTransferStep } from './useWalletTransfer';

const options: {
  kind: TransferKind;
  title: string;
  icon: IconDefinition;
}[] = [
  {
    kind: 'external_transfer',
    title: 'Someone else',
    icon: faArrowRightArrowLeft,
  },
  {
    kind: 'own_transfer',
    title: 'Myself',
    icon: faCircleUser,
  },
];

export default function TransferTypeSelector() {
  const { setTransferKind, goNextStep, transferKind, resetAndClose } = useWalletTransfer();
  return (
    <>
      <SidebarHeader title={'Pay and transfer funds'} onClose={resetAndClose} />
      <div className="mx-6 mb-8 h-full md:mx-8 md:mb-8">
        <h4 className="mb-2 font-medium">Transfer to yourself or someone else?</h4>
        <div className="flex h-full flex-col justify-between pb-8">
          <RadioGroup className="gap-2">
            {options.map(({ kind, title, icon }) => (
              <EntryButton
                key={kind}
                onClick={() => {
                  setTransferKind(kind);
                  if (kind == 'own_transfer') {
                    goNextStep(WalletTransferStep.TransferDetails);
                  } else {
                    goNextStep();
                  }
                }}
                title={title}
                startIcon={
                  <FontAwesomeIcon
                    icon={icon}
                    className={classNames('text-base', {
                      'text-secondary': kind !== transferKind,
                    })}
                  />
                }
              />
            ))}
          </RadioGroup>
        </div>
      </div>
    </>
  );
}
