import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import WarningNotification from '@app/components/layout/WarningNotification';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useContext, useState } from 'react';

import PayrollContext from '../payroll-context';
import ActionButton from './ActionButton';
import StatusPill from './StatusPill';

export default function DriverOptionsModal({
  isActive,
  reset,
  driver,
}: {
  isActive: boolean;
  reset: () => void;
  driver?: FlattenedPayrollData;
}): ReactElement {
  const { currentDriver } = useContext(PayrollContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addToast } = useToasts();
  const driverToActUpon = driver || currentDriver;

  const closeModal = () => {
    setError('');
    setSuccess(false);
    reset();
  };

  const resendInvite = async () => {
    if (success) {
      return;
    }
    setLoading(true);

    try {
      await axios.post(`/drivers/${driverToActUpon.id}/payroll_invite`);
      setLoading(false);
      setSuccess(true);
      addToast(
        `Link sent to ${driverToActUpon.first_name} ${
          driverToActUpon.email ? `at ${driverToActUpon.email}.` : ''
        }`,
        {
          appearance: 'success',
        },
      );
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        return;
      }

      const message = e.response?.data?.errors?.[0] || '';
      setLoading(false);
      setSuccess(false);
      setError(
        `There was a problem sending the update link. ${message.concat(
          message.length > 0 ? '. ' : '',
        )}`,
      );
    }
  };

  let buttonText = success ? 'Link sent!' : 'Send reset link';
  if (loading) {
    buttonText = 'Sending...';
  }

  return (
    <>
      <Modal open={isActive} toggle={closeModal}>
        <ModalHeader title="Driver Options" onClose={closeModal} />
        <ModalBodyContent>
          {error && <ErrorNotification error={error} />}
          <div className="flex w-full items-baseline justify-between leading-4">
            <div>
              <div className="leading-5">
                <h3 className="font-bold">{driverToActUpon.name}</h3>
              </div>
            </div>
            <div>
              <StatusPill
                isMobile={false}
                status={driverToActUpon.status}
                taxClassification={driverToActUpon.tax_classification}
              />
            </div>
          </div>
          <div className="text-sm text-gray-600">{driverToActUpon.email}</div>
          <div className="text-sm text-gray-600">{driverToActUpon.phone}</div>
          {driverToActUpon.status === 'update_required' && (
            <div className="my-4">
              <WarningNotification>
                {driverToActUpon.name} needs to update their payment details. Please send a reset
                link below to get them back online.{' '}
              </WarningNotification>
            </div>
          )}
          <div className="align-start w-full flex-col">
            <p className="mt-4 text-base text-gray-600">
              Send {driverToActUpon.first_name} a link to update or reset their payment information.
            </p>
          </div>
        </ModalBodyContent>
        <ModalFooter>
          {driver ? (
            <Button onClick={resendInvite} size="small" disabled={loading}>
              {buttonText}
            </Button>
          ) : (
            <ActionButton disabled={loading} primary onClick={resendInvite}>
              {buttonText}
            </ActionButton>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}
