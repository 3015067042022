import { SettingsPageAddressType, SettingsPageEntityType } from '@app/constants/settings';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';

export const SettingsUserFriendlyAddress = ({
  address1,
  address2,
  city,
  state,
  zip,
  typeOfAddress,
}: {
  id?: string;
  type: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zip: string;
  typeOfAddress: SettingsPageAddressType;
}): ReactElement => {
  return (
    <div className="text-base font-semibold not-italic text-gray-900">
      {address1}
      <br />
      {address2 && (
        <>
          {address2}
          <br />
        </>
      )}
      {city}, {state} {zip}
      <br />
      <div className="flex  items-center pt-2">
        <FontAwesomeIcon icon={faBuilding} className={`text-grey6`} />
        <div className="pl-2 text-sm font-normal not-italic text-gray-600">
          {typeOfAddress === SettingsPageAddressType.COMPANY_ADDRESS
            ? 'Company Address'
            : 'Card Shipping Address'}
        </div>
      </div>
    </div>
  );
};

export const SettingsCurrentValue = ({
  type,
  value,
}: {
  type: SettingsPageEntityType;
  value: string | IAddressProps | boolean;
}): ReactElement | null => {
  switch (type) {
    case SettingsPageEntityType.EMAIL_ADDRESS:
    case SettingsPageEntityType.PHONE_NUMBER:
    case SettingsPageEntityType.TELEMATICS_NOTIFICATIONS_ENABLED:
    case SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME:
      return (
        <span className="order-1 flex flex-none flex-grow-0 items-center self-stretch text-sm font-semibold not-italic text-gray-900">
          {typeof value == 'string' && value}
        </span>
      );

    case SettingsPageEntityType.OTP_BACKUP_EMAIL:
      return (
        <span className="order-1 flex flex-none flex-grow-0 items-center self-stretch text-sm font-semibold not-italic text-gray-900">
          {(value as string | null) || 'Not Set'}
        </span>
      );

    case SettingsPageEntityType.FLEET_TYPE:
      return (
        <span className="order-1 flex flex-none flex-grow-0 items-center self-stretch text-sm font-semibold not-italic text-gray-900">
          {capitalize((value as string) || 'unknown')}
        </span>
      );

    case SettingsPageEntityType.COMPANY_ADDRESS:
    case SettingsPageEntityType.CARD_SHIPPING_ADDRESS:
      return (
        <div className="space-y-4">
          <SettingsUserFriendlyAddress {...(value as IAddressProps)} />
        </div>
      );

    default:
      return null;
  }
};
