import { ChargeEventsContext } from '@app/contexts/ChargeEventsContextComponent';
import { faArrowTrendDown, faArrowTrendUp, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactElement, useContext, useState } from 'react';

export const TransactionSavingsFilter = ({
  onTypeSelect,
}: {
  onTypeSelect: (v: 'all' | 'discounted' | 'potential_savings') => void;
}): ReactElement => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value;
    if (selectedValue === selectedOption) return;
    setSelectedValue(selectedOption);
    onTypeSelect(selectedOption as 'all' | 'discounted' | 'potential_savings');
  };
  const { queryParams } = useContext(ChargeEventsContext);
  const [selectedValue, setSelectedValue] = useState<string>(() => {
    return queryParams.with_savings
      ? 'discounted'
      : queryParams.with_potential_savings
      ? 'potential_savings'
      : 'all';
  });
  return (
    <FormControl className="flex-2 min-w-min">
      <Select
        size="small"
        value={selectedValue}
        onChange={handleChange}
        renderValue={(value) =>
          value === 'discounted' ? (
            <div className="flex gap-1">
              <div className="bg-accent-11 flex items-center justify-center rounded-full">
                <FontAwesomeIcon icon={faArrowTrendUp} className="h-2.5 w-2.5 p-1 text-white" />
              </div>
              <div className="text-primary">Saved</div>
            </div>
          ) : value === 'potential_savings' ? (
            <div className="flex gap-1">
              <div className="bg-warning-1 flex items-center justify-center rounded-full">
                <FontAwesomeIcon icon={faArrowTrendDown} className="h-2.5 w-2.5 p-1 text-white" />
              </div>
              <div className="text-primary">Missed savings</div>
            </div>
          ) : (
            <div className="flex gap-1">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="bg-secondary h-2.5 w-2.5 rounded-full p-1 text-white"
                />
              </div>
              <div className="text-primary">All Transactions</div>
            </div>
          )
        }
      >
        <MenuItem value="all">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faDollarSign}
              className="bg-secondary h-2.5 w-2.5 rounded-full p-1 text-white"
            />
          </div>
          <ListItemText className="text-primary">All Transactions</ListItemText>
        </MenuItem>
        <MenuItem value="discounted">
          <div className="bg-accent-11 flex items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faArrowTrendUp} className="h-2.5 w-2.5 p-1 text-white" />
          </div>
          <ListItemText className="text-primary">Saved</ListItemText>
        </MenuItem>
        <MenuItem value="potential_savings">
          <div className="bg-warning-1 flex items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faArrowTrendDown} className="h-2.5 w-2.5 p-1 text-white" />
          </div>
          <ListItemText className="text-primary">Missed savings</ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
