import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { PaymentMethodResponseType } from '@app/@types/payments.types';
import NoBanks from '@app/assets/images/svg/no-banks.svg';
import PaymentMethodsList from '@app/components/PaymentMethods/PaymentMethodsList';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mapBankAndDebitCardData } from '../../components/PaymentMethods/PaymentMethodUtils';
import AddRecipientModal from './WalletOverview/TransferFunds/PayNow/AddRecipientModal';
import EditRecipientModal from './WalletOverview/TransferFunds/PayNow/EditRecipientModal';
import {
  RecipientData,
  RecipientToCreate,
  RecipientToEdit,
  recipientDataToNewRecipient,
} from './WalletOverview/TransferFunds/transfer.types';

const Recipient = ({
  recipient,
  setEditRecipient,
  removeRecipient,
}: {
  recipient: RecipientData;
  setEditRecipient: (recipient: RecipientToCreate) => void;
  removeRecipient: (recipient: RecipientData) => Promise<void>;
}): ReactElement => {
  const { isMobile } = useWindowWidth();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const deleteRecipient = async () => {
    if (!confirmRemove) {
      setConfirmRemove(true);
      return;
    }

    await removeRecipient(recipient);
  };

  if (isMobile) {
    return (
      <div className="mb-4 flex flex-col items-start bg-white p-4">
        <div className="mr-1 text-base font-bold text-gray-700">
          {recipient.display_name || recipient.billing_details.name}
        </div>
        <div className="text-[16px] text-gray-600">
          {recipient.payment_details.bank_account.account_holder_type === 'company'
            ? 'Business'
            : 'Personal'}{' '}
          Account
        </div>
        <div className="mt-2 flex flex-row gap-2">
          <Button
            size="small"
            onClick={() => setEditRecipient(recipientDataToNewRecipient(recipient))}
          >
            Edit
          </Button>
          <Button size="small" onClick={() => deleteRecipient()}>
            {confirmRemove ? 'Confirm Removal' : 'Remove'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-4 flex justify-between rounded-md bg-white p-4">
      <div className="flex flex-col items-start py-4">
        <div className="flex">
          <div className="mr-1 text-base font-bold text-gray-700">
            {recipient.display_name || recipient.billing_details.name}
          </div>
          <div className="">
            |{' '}
            {recipient.payment_details.bank_account.account_holder_type === 'company'
              ? 'Business'
              : 'Personal'}{' '}
            Account
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          size="small"
          onClick={() => setEditRecipient(recipientDataToNewRecipient(recipient))}
        >
          Edit
        </Button>
        <Button size="small" onClick={() => deleteRecipient()}>
          {confirmRemove ? 'Confirm Removal' : 'Remove'}
        </Button>
      </div>
    </div>
  );
};

const fetchInboundPaymentMethods = () => {
  return axios.get<PaymentMethodResponseType>('/payments/payment_methods', {
    params: {
      include: 'payment_method_detail,bank_accounts,debit_cards,customer_treasury_details',
    },
  });
};

export default function ManageRecipients({
  recipients,
  addRecipient,
  editRecipient,
  removeRecipient,
}: {
  recipients: RecipientData[];
  addRecipient: (recipient: RecipientToCreate) => Promise<void>;
  editRecipient: (recipient: RecipientToEdit) => Promise<void>;
  removeRecipient: (recipient: RecipientData) => Promise<void>;
}): ReactElement {
  const [inboundBankAccounts, setInboundBankAccounts] = useState<
    BankAccountPaymentMethodCombined[]
  >([]);

  const [error, setError] = useState<Error | null>(null);

  const {
    isLoading,
    error: paymentMethodsError,
    data: paymentMethodsData,
  } = usePaymentMethodsQuery();

  useEffect(() => {
    if (paymentMethodsData) {
      const { combinedPaymentMethods } = mapBankAndDebitCardData(paymentMethodsData.data || []);
      setInboundBankAccounts(combinedPaymentMethods.bankAccounts);
    }
  }, [paymentMethodsData]);

  const [recipientToEdit, setRecipientToEdit] = useState<RecipientToCreate | null>(null);
  const [isAddRecipientModalActive, setAddRecipientModalActive] = useState(false);

  return (
    <>
      <div>
        {paymentMethodsError && <ErrorNotification error={paymentMethodsError.message} />}
        {error && <ErrorNotification error={error.message} />}
        <div className="flex w-full flex-col items-center justify-between md:flex-row">
          <div className="flex flex-col">
            <h1 className="text-[22px] font-bold">Manage Recipients</h1>
            <div className="text-base">Send funds to these accounts from your AtoB Wallet.</div>
            <div className="text-sm">
              Add additional users to this account by clicking the Add New Recipient button.
            </div>
          </div>
          <div className="xs:w-[200px] mt-4 w-full">
            <Button size="medium" onClick={() => setAddRecipientModalActive(true)}>
              Add New Recipient
            </Button>
          </div>
        </div>
      </div>
      <>
        {isLoading && <Skeleton />}
        {recipients.length > 0 ? (
          <>
            <h2 className="text-[22px] font-bold">Recipients</h2>
            <div className="flex w-full flex-col divide-y-2">
              {recipients.map((recipient) => (
                <div key={recipient.id}>
                  <Recipient
                    key={recipient.display_name}
                    recipient={recipient}
                    setEditRecipient={setRecipientToEdit}
                    removeRecipient={removeRecipient}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <NoRecipients />
        )}
      </>
      <>
        <h2 className="text-[22px] font-bold">Accounts you own</h2>
        {inboundBankAccounts.length > 0 ? (
          <>
            <PaymentMethodsList
              bankAccounts={inboundBankAccounts}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              debitCards={null}
              setError={setError}
              handleRefreshPaymentMethods={fetchInboundPaymentMethods}
              onLinkingCallback={fetchInboundPaymentMethods}
            />
          </>
        ) : (
          <>
            <NoBankAccounts />
          </>
        )}
      </>
      <EditRecipientModal
        open={!!recipientToEdit}
        recipient={recipientToEdit || ({} as RecipientToCreate)}
        onEditRecipient={editRecipient}
        reset={() => setRecipientToEdit(null)}
      />
      <AddRecipientModal
        open={isAddRecipientModalActive}
        onAddRecipient={addRecipient}
        reset={() => setAddRecipientModalActive(false)}
      />
    </>
  );
}

const NoRecipients = (): ReactElement => {
  return (
    <div className="flex h-64 w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/file-folder.svg" alt="Card" />
      <div className="mt-4 flex max-w-[300px] flex-col items-center text-center">
        <h2 className="text-[18px] font-semibold text-gray-500">No recipients added.</h2>
        <p className="text-[14px] text-gray-500">Click 'Add' above.</p>
      </div>
    </div>
  );
};

const NoBankAccounts = (): ReactElement => {
  return (
    <div className="flex h-64 w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src={NoBanks} alt="Card" />
      <div className="mt-4 flex max-w-[300px] flex-col items-center text-center">
        <h2 className="text-[18px] font-semibold text-gray-500">No bank accounts added.</h2>
        <p className="text-[16px] text-gray-500">
          Add an account{' '}
          <b className="bold text-atob-green">
            <Link to="/billing/payment-methods">here</Link>
          </b>
        </p>
      </div>
    </div>
  );
};
