import StripeContext from '@app/contexts/stripeContext';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import { useState, useContext } from 'react';
import useSWRMutation from 'swr/mutation';
import { v4 as uuid } from 'uuid';

type ApiResponse = {
  data: {
    attributes: {
      client_secret: string;
    };
  };
};

type AddFundsMutationHookParams = {
  idempotency_key: string;
  amount: number;
  description: string;
  payment_method_id: string | null;
};

export default function useAddFunds({
  onSuccess,
  onFailure,
  amount,
  description,
  selectedPaymentMethodId,
  continueOnboarding,
}: {
  onSuccess: () => void;
  onFailure: (e: unknown) => void;
  amount: number;
  description: string;
  selectedPaymentMethodId: string | null;
  continueOnboarding?: () => void;
}) {
  const [idempotencyKey] = useState(uuid());
  const { clientSecret, setClientSecret } = useContext(StripeContext);

  const { trigger, isMutating: isLoading } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    AddFundsMutationHookParams
  >(
    {
      url: '/treasury/financial_account/deposit',
    },
    postFetcher,
    {
      onSuccess: (data) => {
        const clientSecret = (data as ApiResponse)?.data?.attributes?.client_secret;
        if (clientSecret) {
          setClientSecret(clientSecret);
        } else {
          onSuccess();
        }
        continueOnboarding?.();
      },
      onError: (e) => {
        onFailure(e);
      },
    },
  );

  const submitFunds = () => {
    trigger({
      idempotency_key: idempotencyKey,
      amount: amount,
      description: description,
      payment_method_id: selectedPaymentMethodId,
    });
  };

  return {
    submitFunds,
    clientSecret,
    setClientSecret,
    isLoading,
  };
}
