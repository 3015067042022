import useProduct from '@app/hooks/useProduct';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function OONFeeBanner() {
  const [oon_fee_discount_boost, oon_fee_card_fee_waived] = useProduct(
    'oon_fee_discount_boost',
    'oon_fee_card_fee_waived',
  );

  if (oon_fee_discount_boost) {
    return (
      <MarketingBanner bannerKey="oon_fee_discount_boost">
        As a valued cardholder, enjoy an additional 5¢ per gallon discount at TA, Petro, and TA
        Express locations on top of your current savings. As a reminder, a $2 fee now applies to
        fuel purchases made outside of our discount network.
      </MarketingBanner>
    );
  }

  if (oon_fee_card_fee_waived) {
    return (
      <MarketingBanner bannerKey="oon_fee_card_fee_waived">
        As a valued cardholder, enjoy waived monthly card fees and no extra charge for adding
        additional cards to your account. As a reminder, a $2 fee now applies to fuel purchases made
        outside of our discount network.
      </MarketingBanner>
    );
  }

  return null;
}
