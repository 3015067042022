import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { faCheck, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { TransferSummary } from '../TransferSummary';
import {
  computeEstimatedReceptionTime,
  computeTotalFeeAmount,
  transferTypeMapping,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
} from '../utils';

export enum SuccessIcon {
  CHECK = 'check',
  CLOCK = 'clock',
}

export const SuccessModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  method,
  title = 'Transfer complete',
  secondaryTitle,
  icon,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee: FeeConfiguration;
  description: string;
  method: PaymentMethodType;
  title?: string;
  secondaryTitle?: string | ReactElement;
  icon: SuccessIcon;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = computeTotalFeeAmount({ transferAmountCents, feeData: fee });

  const iconMapping = {
    [SuccessIcon.CHECK]: {
      icon: faCheck,
      className: 'bg-accent-12 text-accent-10',
    },
    [SuccessIcon.CLOCK]: {
      icon: faClock,
      className: 'bg-accent-23 text-accent-20',
    },
  };

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader
        title={title}
        secondaryTitle={secondaryTitle}
        className="justify-center text-center"
        headerImage={
          <FontAwesomeIcon
            icon={iconMapping[icon].icon}
            className={classNames(
              'flex h-5 w-5 justify-self-center rounded-full p-4',
              iconMapping[icon].className,
            )}
          />
        }
      />
      <ModalBodyContent>
        <div className="mb-4 mt-2 flex flex-col gap-6">
          <TransferSummary
            transferType={transferTypeMapping[method]}
            arrivalTime={formatDate({
              dateValue: computeEstimatedReceptionTime(fee.type),
              pattern: 'MMMM D, YYYY',
            })}
            recipientName={destination.recipientName}
            feePercentage={fee.percentage}
            feeAmount={feeAmount}
            description={description}
            amount={transferAmountCents}
          />
          <div className="flex gap-3">
            <Button color="primary" fullWidth onClick={onClose} className="p-3">
              Back to Wallet
            </Button>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
