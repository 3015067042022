import useShippingAddress from '@app/hooks/use-shipping-address';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { faCircleCheck, faCube, faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import { CreditEnablementNetNewModal } from '../Modals/CreditEnablementNetNew/CreditEnablementNetNewModal';
import { MarketingBanner } from './Designs/MarketingBanner';
import { StatusBanner } from './Designs/StatusBanner';

const CreditEnablementNetNewBanner = (): ReactElement | null => {
  const [CREDIT_ENABLEMENT_PROGRAM_NET_NEW_OFFER_OPEN, CREDIT_ENABLEMENT_PROGRAM_NET_NEW_ENROLLED] =
    useFeatureFlags(
      'credit_enablement_program_net_new_offer_open',
      'credit_enablement_program_net_new_enrolled',
    );

  const customer = useCustomer();
  const shippingAddress = useShippingAddress(customer);

  const cardsDelivered = customer.any_cards_delivered;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (CREDIT_ENABLEMENT_PROGRAM_NET_NEW_OFFER_OPEN) {
    return (
      <>
        <CreditEnablementNetNewModal
          isOpen={isModalOpen}
          toggle={toggle}
          onComplete={() => {
            location.reload();
          }}
        />
        <MarketingBanner
          onClick={() => setIsModalOpen(true)}
          actionText="Activate Credit Line"
          bannerKey="credit-enablement-program-net-new"
          title="Offer"
          icon={faPartyHorn}
          nonDismissable
        >
          You are approved for a credit line! Complete steps to activate and get your fuel cards
          shipped.
        </MarketingBanner>
      </>
    );
  }

  if (CREDIT_ENABLEMENT_PROGRAM_NET_NEW_ENROLLED) {
    return cardsDelivered ? (
      <StatusBanner
        icon={faCircleCheck}
        bannerKey="card_status_delivered_notification"
        iconColor="green"
      >
        <b className="font-semibold text-gray-700">Cards Delivered: </b>
        Your AtoB cards have been delivered to {shippingAddress}.
      </StatusBanner>
    ) : (
      <StatusBanner icon={faCube} bannerKey="card_status_shipped_notification" nonDismissable>
        <b className="font-semibold text-gray-700">Cards Shipped: </b>
        Your AtoB cards will be shipped to {shippingAddress}.
      </StatusBanner>
    );
  }

  return null;
};

export default CreditEnablementNetNewBanner;
