import { TextField } from '@mui/material';
import React, { ReactElement } from 'react';
const moveToNextInput = (fieldIndex: number) => {
  const nextSibling: HTMLInputElement | null = document.querySelector(
    `input[name=otp_input-${fieldIndex + 1}]`,
  );
  if (nextSibling !== null) {
    nextSibling.focus();
  }
};
const OtpInput = ({
  inputState,
  setInputState,
}: {
  inputState: string[];
  setInputState: (inputState: string[] | ((prevState: string[]) => string[])) => void;
}): ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const [, fieldIndexString] = name.split('-');
    const fieldIndex: number = parseInt(fieldIndexString, 10);
    const notLastInputField = fieldIndex < 6;
    if (value === '') {
      setInputState([...inputState.slice(0, fieldIndex), '', ...inputState.slice(fieldIndex + 1)]);
      if (fieldIndex - 1 >= 0) {
        moveToNextInput(fieldIndex - 2);
      }
      return;
    }
    if (value.length > 1) {
      setInputState((prevState: string[]): string[] => {
        const newState = [...prevState];
        newState[fieldIndex] = value.slice(-1);
        return newState;
      });
      moveToNextInput(fieldIndex);
      return;
    }
    if (notLastInputField) {
      setInputState((prevState: string[]): string[] => {
        const newState = [...prevState];
        newState[fieldIndex] = value;
        return newState;
      });
      moveToNextInput(fieldIndex);
      return;
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLElement>) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('text');
    if (pastedValue.length !== 6) {
      return;
    }
    setInputState(pastedValue.split(''));
    (document.activeElement as HTMLElement).blur();
  };
  return (
    <div className="my-4 flex">
      <div className="fs-mask data-hj-suppress flex w-full justify-center">
        {inputState.map((_, index) => (
          <div key={index} className="flex justify-center">
            <TextField
              key={index}
              size="small"
              className="ml-2 max-w-[44px] rounded !border-gray-300 text-center text-[28px] first:border-l-gray-400"
              type="text"
              name={`otp_input-${index}`}
              slotProps={{
                htmlInput: {
                  inputMode: 'numeric',
                },
              }}
              value={inputState[index]}
              onChange={handleChange}
              onFocus={(e) => e.target.select()}
              onPaste={(e) => {
                if (index !== 0) {
                  return;
                }
                handlePaste(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default OtpInput;
