import GreenHands from '@app/assets/images/svg/green_hands_with_dollar_sign.svg';
import GreenMessageBubble from '@app/assets/images/svg/green_heart_in_message_bubble.svg';
import GreenPerson from '@app/assets/images/svg/green_person.svg';
import CopyIcon from '@app/assets/images/svg/white_copy_icon.svg';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import Divider from '@app/components/elements/Divider';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import useCustomer from '@app/hooks/useCustomer';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { useState } from 'react';

const CopyLinkSection = () => {
  const customer = useCustomer();
  const windowWidth = useWindowWidth();
  const { addToast } = useToasts();

  const messageCopy =
    "Hey! I'm loving the fuel discounts on my AtoB Fuel Card and think you will too. Plus you'll get a $250 welcome offer if you sign up using my link: ";
  const referralLink = customer.unique_referral_url;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(messageCopy + referralLink).then(() => {
      addToast('Message copied to clipboard', {
        appearance: 'success',
      });
    });
  };

  if (!customer.show_referral_url) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="text-primary font-semibold">Referral link</p>
      <div className="border-level-3 flex w-full items-center gap-2 rounded-md border px-3 py-2">
        <div className="text-primary max-h-30 flex-grow overflow-y-scroll text-sm sm:text-base">
          {messageCopy}
          <a
            href={referralLink}
            className="text-primary hover:text-secondary font-semibold underline"
          >
            {referralLink}
          </a>
        </div>
        <button
          onClick={copyToClipboard}
          className="bg-primary text-level-0 hover:bg-secondary flex flex-shrink-0 items-center gap-1 rounded-md px-4 py-2"
        >
          <img src={CopyIcon} alt="Copy" />
          {windowWidth.isDesktop ? 'Copy message' : 'Copy'}
        </button>
      </div>
    </div>
  );
};

const HowItWorksStep = ({
  icon,
  title,
  description,
}: {
  icon: string;
  title: string;
  description: string;
}) => (
  <div className="m-1 flex items-start gap-4">
    <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full">
      <img src={icon} alt={title} />
    </div>
    <div>
      <p className="text-primary font-semibold">{title}</p>
      <p className="text-secondary">{description}</p>
    </div>
  </div>
);

export default function ReferralProgram() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const customer = useCustomer();

  return (
    <PageContentWrapper header={<Header title="Referrals" />}>
      <RoundedCard className="p-5">
        <h3 className="text-primary mb-2 text-lg font-semibold">
          Refer a Friend. Give $250, get $250 in rewards.
        </h3>
        <p className="text-secondary mb-4">
          {!customer.show_referral_url
            ? 'Unlock your unique referral link after making your first transaction with your new AtoB Fuel Card.'
            : 'Send a referral with a few clicks and earn rewards if your friend is approved for the AtoB Fuel Card.'}
        </p>
        {customer.show_referral_url && <CopyLinkSection />}
        <Divider />
        <div>
          <h4 className="text-primary mb-6 font-semibold">How it works?</h4>
          <div className="flex flex-col gap-4">
            <HowItWorksStep
              icon={GreenMessageBubble}
              title="Invite your friends"
              description="Share your personalized referral link via email, text or any way you choose."
            />
            <HowItWorksStep
              icon={GreenPerson}
              title="Your friend signs up"
              description="They’ll get a $250 reward when they activate their card and meet spend goals within 30 days."
            />
            <HowItWorksStep
              icon={GreenHands}
              title="Earn $250 in rewards"
              description="Once your friend is approved for a credit line with the AtoB Fuel Card, you'll earn a $250 referral reward applied as a statement credit to your account within 10 days."
            />
          </div>
          <div className="ml-3 mt-5">
            By taking part in the Referral Program you agree with the{' '}
            <span className="cursor-pointer font-semibold underline" onClick={openModal}>
              Terms and Conditions.
            </span>
          </div>
        </div>
      </RoundedCard>

      <Modal open={isModalOpen} toggle={closeModal}>
        <ModalHeader title="Referral Program Terms & Conditions" onClose={closeModal} />
        <ModalBodyContent>
          <div className="text-primary mb-4 text-sm">
            AtoB reserves the right to withhold or revoke rewards if it determines, at its sole
            discretion, that any fraudulent, abusive, or suspicious behavior has occurred in
            connection with the referral program.
          </div>
          <div className="text-primary mb-4 text-sm">
            Rewards are not retroactive and will not be issued to accounts closed, canceled, or
            otherwise inactive at the time the reward is processed.
          </div>
          <div className="text-primary mb-4 text-sm">
            Eligibility for rewards requires that referrals are made using the unique referral link
            provided by AtoB. The referred party must:
            <ul className="list-disc pl-6">
              <li className="mb-1">Not be an existing or former AtoB customer.</li>
              <li className="mb-1">Not already be engaged in an active sales process with AtoB.</li>
              <li className="mb-1">
                AtoB customers are welcome to refer as many people as they&apos;d like; however,
                referral rewards are limited to $250 per referred customer, with a maximum total
                reward of $500. This means customers are eligible to earn rewards for up to two
                referrals ($250 per referral) but will not receive additional rewards beyond $500,
                regardless of the number of customers successfully referred.
              </li>
            </ul>
          </div>
          <div className="text-primary mb-4 text-sm">
            AtoB reserves the right to modify, suspend, or terminate the referral program at any
            time without prior notice. All decisions made by AtoB regarding reward issuance and
            eligibility are final.
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <Button
            onClick={closeModal}
            className="bg-primary hover:bg-secondary w-full rounded-b-lg py-3 font-semibold text-white"
          >
            Got it
          </Button>
        </ModalFooter>
      </Modal>
    </PageContentWrapper>
  );
}
