import { faCaretLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, HTMLAttributes, PropsWithChildren } from 'react';

/**
 * All of these functions are used for full screen containers on mobile.
 * Currently only used for itemization flow on mobile.
 * Pay now flow does not use this and is intentionally using a modal experience to simulate a faster/more seamless experiene.
 */
export function MobilePayrollPaymentContainer({
  children,
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div className="z-base absolute inset-0 overflow-hidden bg-black bg-opacity-25">
      <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:hidden sm:pl-16">
        <div className="pointer-events-auto z-30 w-screen max-w-full sm:max-w-md">
          <div className="flex h-full flex-col  bg-white shadow-xl">{children}</div>
        </div>
      </div>
    </div>
  );
}

export const MobilePayrollPaymentFooter = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  return (
    <div className="mt-0 flex flex-shrink-0 flex-col justify-end gap-4 py-4 sm:mb-4">
      <footer className="flex w-full flex-col items-center justify-end gap-y-2">{children}</footer>
    </div>
  );
};

export const MobilePayrollPaymentTitle = ({
  driverFullName,
  back,
}: {
  driverFullName: string;
  back: () => void;
}): ReactElement => {
  return (
    <div className="px-4 pb-2 pt-6 sm:px-6">
      <div className="flex flex-col justify-between">
        <div onClick={back} className="cursor-pointer">
          <FontAwesomeIcon className="fa-1x" icon={faCaretLeft} />
          <span className="ml-4 mt-2 text-lg font-semibold">Back</span>
        </div>
        <div className="items-left ml-2 mt-4 flex flex-col">
          <span className="mt-2 text-lg font-semibold">Payment for {driverFullName}</span>
          <span className="text-base">
            Calculation method is prefilled based on last used method for this driver.
          </span>
        </div>
      </div>
    </div>
  );
};

export const MobilePayrollPaymentBody = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  return (
    <div className="h-0 flex-1 overflow-y-auto">
      <div className="flex flex-1 flex-col justify-between">
        <div className="px-6">
          <div className="flex flex-col items-end pb-8 pt-4">{children}</div>
        </div>
      </div>
    </div>
  );
};
