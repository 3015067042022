import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';

import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

function PayrollTaxesHowTo(): ReactElement {
  return (
    <div className="container">
      <h1 className="my-6 text-3xl font-semibold text-gray-800">
        How to File Taxes with AtoB Payroll
      </h1>
      <p className="my-6 text-2xl">
        <b className="text-green-800">AtoB</b> is partnering with{' '}
        <a className="font-bold text-green-800 underline" href="https://www.tax1099.com/">
          Tax1099
        </a>{' '}
        this year to provide you with a full suite of 1099 tax reporting products.{' '}
      </p>
      <p className="my-6 text-lg">
        We will provide you with templates of your information and your drivers’ information you
        should double-check, and allow you to select if you want a 1099-NEC or a 1099-MISC form.
        Once you download this form, you can adjust the amounts in an Excel sheet. This means if you
        paid your driver prior to using AtoB, you can still file with AtoB and Tax1099’s
        partnership. Please see 1099-NEC template examples{' '}
        <a
          className="text-green-800 underline"
          href="https://docs.google.com/spreadsheets/d/1SfVuY67-HGBlYGwGe-6CIcngZiMkz9qgeyqAfAZWRpI/"
        >
          here
        </a>{' '}
        and 1099-MISC template examples{' '}
        <a
          className="text-green-800 underline"
          href="https://docs.google.com/spreadsheets/d/1sg5bSW6hc7Ki1dCm2prqLBjrcxEEMPn1Jgit7Lx-aS8/"
        >
          here.
        </a>{' '}
      </p>
      <p className="my-6 text-lg">
        With our partnership, we will be giving you 20% off Tax1099’s pricing. They have the full
        suite of 1099 products - W9 forms, 1099-NEC, 1099-MISC, etc. Please see{' '}
        <a
          className="text-green-800 underline"
          href="https://www.tax1099.com/1099-forms/efile-form-1099-NEC"
        >
          here
        </a>{' '}
        for pricing.
      </p>
      <p className="my-6 text-lg">
        <a
          className="text-green-800 underline"
          href="https://classic.tax1099.com/register?utm_source=atob24ty&utm_medium=partner&utm_campaign=atob2025"
        >
          Register with this link
        </a>{' '}
        and use the coupon code <b>atob24ty</b> at checkout to claim your 20% off.
      </p>
      <p className="my-6 text-lg">
        Please ensure that you also have a W9 form on file for your drivers, including their SSN and
        home address for the 1099 filing. Also note that after you file with Tax1099, they will send
        a copy (Copy B) of your tax form to the driver. AtoB will not be providing tax forms
        individually to 1099 drivers.
      </p>
      <p className="my-6 text-lg">
        <b>Important Disclaimer:</b>{' '}
        <i>
          Any information herein is based on the facts provided to us. Tax law is subject to
          continual change, at times on a retroactive basis and may result in incremental taxes,
          interest or penalties. Should the facts provided to us be incorrect or incomplete or
          should the law or its interpretation change, information in these forms may be
          inappropriate. We are not responsible for updating our advice for changes in law or
          interpretation after the date hereof. No warranty or representation, express or implied,
          is made by CElegan Labs, Inc. (d/b/a “AtoB”), nor does AtoB accept any liability with
          respect to the information and data set forth herein. Distribution hereof does not
          constitute legal, tax, accounting, investment or other professional advice. Recipients
          should consult their professional advisors prior to acting on the information set forth
          herein.
        </i>
      </p>
      <div className="flex max-w-md flex-col gap-px">
        <Button
          size="small"
          onClick={() => {
            window.open(
              'https://staic-files-hosting.s3.us-west-1.amazonaws.com/How_To_File_With_Tax_1099__Instructions.pdf',
              '_blank',
            );
          }}
        >
          Download Instructions
        </Button>
      </div>
    </div>
  );
}

type TaxFormType = '1099-MISC' | '1099-NEC';

export default function PayrollTaxesInformation(): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [taxFormType, setTaxFormType] = useState<TaxFormType>('1099-NEC');
  const formTypeOptions: { id: TaxFormType; label: string; explanation: string }[] = [
    { id: '1099-MISC', label: '1099-MISC', explanation: 'MISC' },
    { id: '1099-NEC', label: '1099-NEC', explanation: 'NEC' },
  ];
  const selectedOption = formTypeOptions.find((option) => option.id === taxFormType);

  const { addToast } = useToasts();
  const downloadAttachment = async (
    taxFormType: TaxFormType,
    year: number,
    setLoading: (loading: boolean) => void,
  ) => {
    setLoading(true);
    let retryCount = 5;
    const tryDownload = async () => {
      try {
        const { data } = await axios.get('/payroll/tax_forms/download', {
          params: {
            tax_form_type: taxFormType,
            year: year,
          },
        });
        window.open(data.url);
        setLoading(false);
      } catch (error: unknown) {
        retryCount--;

        if (!guardAxiosError(error)) {
          addToast('Try later', { appearance: 'info' });
          setLoading(false);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error.response.status === 404 && retryCount > 0) {
          // 404 means the attachment is not ready. Retry in 5 seconds.
          setTimeout(async () => {
            await tryDownload();
          }, 5000);
        } else {
          // If we used all the retry count, directly show the error toast.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          addToast(error.response.data.errors, { appearance: 'info' });
          setLoading(false);
        }
      }
    };

    await tryDownload();
  };

  return (
    <>
      <div className="mb-6 flex w-full items-center justify-between">
        <h2 className="text-2xl font-bold">Tax Forms</h2>
      </div>
      <PageContentWrapper>
        <PayrollTaxesHowTo />
        <div className="flex max-w-md flex-col gap-px">
          <InputLabel className="block text-sm font-medium text-gray-700">Form Number</InputLabel>
          <Select
            className="my-2"
            value={selectedOption?.id}
            onChange={(e) => setTaxFormType(e.target.value as TaxFormType)}
            fullWidth
          >
            {formTypeOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <LoadingButton
            size="small"
            loading={loading}
            onClick={async () => await downloadAttachment(taxFormType, 2024, setLoading)}
          >
            Export Tax Template
          </LoadingButton>
        </div>
      </PageContentWrapper>
    </>
  );
}
