import useChannelPartner from '@app/hooks/useChannelPartner';
import { useNavigate } from 'react-router-dom';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function SendMoneyBanner() {
  const nav = useNavigate();

  const { name } = useChannelPartner();
  const showBanner = name === 'engaged_financial';

  if (!showBanner) {
    return null;
  }

  return (
    <MarketingBanner
      onClick={() => nav('/wallet')}
      actionText="Send Money"
      bannerKey="send_money_banner"
      title="New Product"
    >
      Send money instantly with your Engaged Wallet. Pay yourself, drivers, vendors, or anyone{' '}
      <strong>in seconds</strong> using options like Zelle, PayPal, Venmo or debit card—24/7, even
      on weekends.
    </MarketingBanner>
  );
}
