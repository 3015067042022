import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export const SettingsRowHeadline = ({ type }: { type: SettingsPageEntityType }): ReactElement => {
  return (
    <div className="xs:basis-4/12 flex basis-10/12 flex-col ">
      <div className="group flex items-center rounded-md pb-4 text-sm font-semibold md:font-medium lg:font-semibold xl:text-base">
        <FontAwesomeIcon
          icon={SETTINGS_ROW_OPTIONS[type].icon}
          size="2x"
          className={`my-auto mr-4 h-6 w-6 flex-shrink-0 md:mr-2.5 lg:mr-4`}
        />
        <p className="order-1 flex-none flex-grow-0 basis-9/12 text-xl font-semibold not-italic text-gray-900">
          {SETTINGS_ROW_OPTIONS[type].title}
        </p>
      </div>
      <p className="text-sm font-normal not-italic text-gray-600">
        {SETTINGS_ROW_OPTIONS[type].subtitle}
      </p>
    </div>
  );
};
