import useThemeConfiguration from '@app/app/useThemeConfiguration';
import AmountApplicationBanner from '@app/components/Banner/AmountApplicationBanner';
import AtoBSavingsBanner from '@app/components/Banner/AtoBSavingsBanner';
import ConfirmCardRequestBanner from '@app/components/Banner/ConfirmCardRequestBanner';
import CreditEnablementNetNewBanner from '@app/components/Banner/CreditEnablementNetNewBanner';
import EngagedPromotionBanner from '@app/components/Banner/EngagedPromotionBanner';
import FundboxOfferBanner from '@app/components/Banner/FundboxOfferBanner';
import IFTANudgeBanner from '@app/components/Banner/IFTANudgeBanner';
import InstantTransfersBanner from '@app/components/Banner/InstantTransfersBanner';
import MarketingBannerComponent from '@app/components/Banner/MarketingBanners';
import NetNewCp8Banner from '@app/components/Banner/NetNewCp8Banner';
import OONFeeBanner from '@app/components/Banner/OONFeeBanner';
import ReferralBanner from '@app/components/Banner/ReferralBanner';
import { SecurityDepositNetNewBanner } from '@app/components/Banner/SecurityDepositNetNewBanner';
import SecurityDepositOfferBanner from '@app/components/Banner/SecurityDepositOfferBanner';
import SendMoneyBanner from '@app/components/Banner/SendMoneyBanner';
import TelematicsNudgeBanner from '@app/components/Banner/TelematicsNudgeBanner';
import { EmbeddedNavigationElement } from '@app/components/Navigation/EmbeddedNav/EmbeddedNavigationElement';
import OnboardingSummaryModal from '@app/components/Onboarding/Modals/OnboardingSummaryModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpendAnalysis from '@app/components/SpendAnalysis/SpendAnalysis';
import { TotalSpend } from '@app/components/UberFreight/TotalSpend';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import AccountOverviewTransactions from './AccountOverviewTransactions';
import { FuelProgramCards } from './FuelProgramCards';
import LoadpayDriverAppOnboarding from './LoadpayDriverAppOnboading';

export const SectionTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}): ReactElement => (
  <div
    className={classNames('px-4 pt-4 text-base font-semibold tracking-tight md:px-6', className)}
  >
    {title}
  </div>
);

export default function AccountOverviewShared({
  header,
  overviewCards,
  onboarding,
}: {
  header?: ReactElement;
  overviewCards?: ReactElement | false;
  onboarding: ReactNode;
}): ReactElement {
  const { showSpendAnalysisOnDashboard, showFuelProgramsOnDashboard, showTotalSpend } =
    useThemeConfiguration();
  const [uberFreightTotalSpendTracker] = useFeatureFlags('uber_freight_total_spend_tracker');

  const [showCardsInfo, showBillingInfo] = useProduct('cards', 'billing');
  const { t } = useTranslation();

  return (
    <PageContentWrapper header={header} className="!gap-4">
      {onboarding}
      <OONFeeBanner />
      <ReferralBanner />
      <LoadpayDriverAppOnboarding />
      <IFTANudgeBanner />
      <TelematicsNudgeBanner />
      <AmountApplicationBanner />
      <FundboxOfferBanner />
      <SecurityDepositOfferBanner />
      <EngagedPromotionBanner />
      <CreditEnablementNetNewBanner />
      <SecurityDepositNetNewBanner />
      <AtoBSavingsBanner />
      <ConfirmCardRequestBanner />
      <InstantTransfersBanner />
      <NetNewCp8Banner />
      <SendMoneyBanner />
      <MarketingBannerComponent />

      {overviewCards}

      {showTotalSpend && uberFreightTotalSpendTracker && <TotalSpend />}

      <EmbeddedNavigationElement />

      {showCardsInfo && (
        <RoundedCard className="triumph:bg-transparent triumph:rounded-none">
          <SectionTitle title={t('dashboard.card_transactions.title')} />
          <div className="triumph:px-0 pb-4">
            <AccountOverviewTransactions />
          </div>
        </RoundedCard>
      )}

      {showBillingInfo && showSpendAnalysisOnDashboard && (
        <RoundedCard>
          <SectionTitle title="Spend Analysis" />
          <SpendAnalysis allowViewTransactions={false} />
        </RoundedCard>
      )}

      {showFuelProgramsOnDashboard && <FuelProgramCards />}

      <OnboardingSummaryModal />
    </PageContentWrapper>
  );
}
