import { MarketingBannerData } from '@app/@types/users.types';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import logger from '@app/utils/datadog-logger';
import React from 'react';
import Markdown from 'react-markdown';
import { MarketingBanner } from './Designs/MarketingBanner';

interface MarketingBannerItemProps {
  banner: MarketingBannerData;
}

const MarketingBannerItem: React.FC<MarketingBannerItemProps> = ({ banner }) => {
  const { dismissed } = useEventDismissed(banner.key);

  if (dismissed) return null;

  const handleActionClick = () => {
    logger.info('banner-cta-clicked', { key: banner.key });

    if (!banner.action) return;

    switch (banner.action.type) {
      case 'navigate_internal':
        if (banner.action.path) {
          window.location.href = banner.action.path;
        }
        break;
      case 'navigate_external':
        if (banner.action.url) {
          window.open(
            banner.action.url.startsWith('http')
              ? banner.action.url
              : `https://${banner.action.url}`,
            '_blank',
          );
        }
        break;
      case 'open_modal':
        break;
      default:
        break;
    }
  };

  const renderBannerContent = () => {
    if (!banner.banner_text) return null;
    return <Markdown>{banner.banner_text}</Markdown>;
  };

  return (
    <MarketingBanner
      bannerKey={banner.key}
      title={banner.banner_title}
      nonDismissable={!banner.dismissible}
      {...(banner.action?.type !== 'no_action' && {
        actionText: banner.action?.label,
        onClick: handleActionClick,
      })}
    >
      {renderBannerContent()}
    </MarketingBanner>
  );
};

export default MarketingBannerItem;
