import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import { ReactElement } from 'react';
import { SettingsCurrentValue } from './SettingsCurrentValue';

export const SettingsRowCurrentValue = ({
  type,
  value,
}: {
  type: SettingsPageEntityType;
  value: string | IAddressProps | boolean;
}): ReactElement => {
  let settingsCurrentValue = (
    <div className="space-y-4">
      <SettingsCurrentValue type={type} value={value} />
    </div>
  );

  if (
    type === SettingsPageEntityType.EMAIL_ADDRESS ||
    type === SettingsPageEntityType.PHONE_NUMBER ||
    type === SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME ||
    type === SettingsPageEntityType.OTP_BACKUP_EMAIL
  ) {
    settingsCurrentValue = (
      <p className="order-1 flex flex-none flex-grow-0 items-center self-stretch text-sm font-semibold not-italic text-gray-900">
        <SettingsCurrentValue type={type} value={value} />
      </p>
    );
  }

  return (
    <div className="xs:basis-4/12 xs:block hidden basis-0 flex-col ">
      <p className="order-none mb-2 flex flex-none flex-grow-0 items-center self-stretch text-sm font-normal not-italic text-gray-900">
        {SETTINGS_ROW_OPTIONS[type].title}
      </p>
      {settingsCurrentValue}
    </div>
  );
};
