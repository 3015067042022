import { CustomerData } from '@app/@types/customer.types';
import { CircularProgress, Dialog } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash-es';
import React, { ReactElement, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const AdminSearch = (): ReactElement => {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<CustomerData[]>([]);
  const [searching, setSearching] = useState(false);
  const [shown, setShown] = useState(false);

  useHotkeys(
    'cmd+k, ctrl+k',
    () => {
      setShown(!shown);
      setSearch('');
    },
    {
      enableOnTags: ['INPUT'],
    },
    [shown],
  );

  const performSearch = async (query: string) => {
    setSearching(true);
    const response = await axios.post('/customer/search_customers', { query });
    setSearchResults(response.data);
    setSearching(false);
  };

  const debounceSearch = useMemo(() => debounce(performSearch, 800), []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <Dialog open={shown} onClose={() => setShown(false)} fullWidth>
      <input
        type="text"
        className="input input-field 4 focus:shadow-outline w-full rounded border border-gray-300 p-2 text-sm text-gray-700 focus:outline-none"
        value={search}
        placeholder="Search customers by name"
        onChange={handleSearch}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setShown(false);
          }
        }}
        autoFocus
        data-testid="admin-search-input"
      />
      <div className="mt-4 h-96">
        {searching && (
          <div className="flex h-96 items-center justify-center rounded bg-white">
            <CircularProgress />
          </div>
        )}
        {!searching && searchResults && (
          <div className="max-h-96 divide-y divide-slate-200 rounded bg-white">
            {searchResults.map((result) => (
              <SearchResult key={result.id} result={result} />
            ))}
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface SearchResultProps {
  result: CustomerData;
}
const SearchResult = (props: SearchResultProps): ReactElement => {
  const { result } = props;
  return (
    <div
      className="cursor-pointer p-2 hover:bg-gray-100"
      onClick={() => {
        axios.post(`/customer/switch_customer`, { id: result.id }).then((res) => {
          if (res.data.id) {
            // Navigate to home page after switch account.
            window.location.replace('/');
          }
        });
      }}
    >
      <div>
        <strong>{result.id}</strong> {result.company_name}
      </div>
    </div>
  );
};

export default AdminSearch;
