import Heart from '@app/assets/images/svg/heart_in_message_bubble.svg';
import useCustomer from '@app/hooks/useCustomer';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import { useNavigate } from 'react-router-dom';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function ReferralBanner() {
  const navigate = useNavigate();
  const customer = useCustomer();
  const showBanner = customer.products?.includes('referrals');

  const { dismissed, setDismissed } = useEventDismissed('referral_banner');

  if (dismissed || !showBanner) {
    return null;
  }

  return (
    <MarketingBanner
      onClick={() => {
        setDismissed();
        navigate('/referrals');
      }}
      actionText="Refer now"
      bannerKey="referral_banner"
      iconImg={
        <div className="flex items-center justify-center">
          <img src={Heart} alt="White Heart Icon" className="h-auto max-w-[40px]" />
        </div>
      }
    >
      Refer a friend: give $250, get $250. Send a referral with a few clicks and earn rewards if
      your friend is approved.
    </MarketingBanner>
  );
}
