import { EndpointResponse } from '@app/@types/api.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { InstantPayoutMethod } from '@app/@types/instant_payout_method.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { useMemo } from 'react';
import useSWR from 'swr';
import { RecipientData } from '../transfer.types';
import {
  AccountType,
  FeeConfiguration,
  filterMethodsByTabValue,
  NormalizedDestination,
  PaymentMethodType,
} from '../utils';
import { TransferPanel } from './TransferPanel';
import { useWalletTransfer } from './useWalletTransfer';

const mapAccountType = (type: string) => {
  switch (type) {
    case 'InstantPayoutMethod::Zelle':
      return 'zelle';
    case 'InstantPayoutMethod::Paypal':
      return 'paypal';
    case 'InstantPayoutMethod::Venmo':
      return 'venmo';
  }
};

export default function TransferDetailsForm() {
  const { transferKind, resetAndClose, onCompletedTransfer, walletBalance } = useWalletTransfer();
  const { data: transferMethodsData, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher);

  const { data: recipientDestinationsData, isLoading: loadingRecipientDestinations } = useSWR<
    EndpointResponse<RecipientData[]>
  >({ url: `/treasury/recipients` }, apiGetFetcher);

  const { data: instantPayoutMethodsData, isLoading: loadingInstantPayoutMethodsData } = useSWR<
    EndpointResponse<InstantPayoutMethod[]>
  >({ url: `/treasury/instant_payout_methods` }, apiGetFetcher);

  // const { data: instantPayoutRecipientsData, isLoading: loadingInstantPayoutRecipientsData } =
  //   useSWR<EndpointResponse<InstantPayoutRecipient[]>>(
  //     { url: `/instant_payout/recipients?include=instant_payout_methods,bank_account_recipients` },
  //     apiGetFetcher,
  //   );

  const normalizedExternalDestinations: NormalizedDestination[] = useMemo(() => {
    const processedExternalRecipientDestinationsData = (recipientDestinationsData?.data || [])
      .map((destination) => {
        const isOwned = destination.customer_owned;
        if (!isOwned) {
          return {
            id: destination.id,
            recipientType: 'external',
            name: destination.billing_details.name,
            type: 'bank_account',
            alternateName: destination.display_name,
            lastFour: destination.payment_details.bank_account.mask,
            supportedNetworks: destination.payment_details.bank_account
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: destination.billing_details.name,
            needsAddress:
              destination.billing_details.address?.address1 == null ||
              destination.billing_details.address?.address1 === '',
          };
        }
        return null;
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    const processedZelleAccountDestinations = (instantPayoutMethodsData?.data || []).map(
      (destination) => {
        return {
          id: destination.id,
          recipientType: 'external',
          name: destination.username,
          type: `${mapAccountType(destination.type)}_account`,
          alternateName: destination.nickname,
          lastFour: '',
          supportedNetworks: [mapAccountType(destination.type)] as PaymentMethodType[],
          brand: null,
          recipientName:
            destination.username + (destination.nickname ? ` (${destination.nickname})` : ''),
          needsAddress: false,
        };
      },
    ) as NormalizedDestination[];

    return processedExternalRecipientDestinationsData.concat(processedZelleAccountDestinations);
  }, [recipientDestinationsData, instantPayoutMethodsData]);

  const { data: ownDestinationsData, isLoading: loadingOwnDestinations } = useSWR<
    EndpointResponse<PaymentMethodCombined[]>
  >(PAYMENT_METHOD_QUERY_KEY, apiGetFetcher);

  const normalizedOwnDestinations: NormalizedDestination[] = useMemo(() => {
    if (!ownDestinationsData?.data) return [];

    const processedZelleAccountDestinations = (instantPayoutMethodsData?.data || []).map(
      (destination) => {
        return {
          id: destination.id,
          recipientType: 'external',
          name: destination.username,
          type: `${mapAccountType(destination.type)}_account`,
          alternateName: destination.nickname,
          lastFour: '',
          supportedNetworks: [mapAccountType(destination.type)] as PaymentMethodType[],
          brand: null,
          recipientName:
            destination.username + (destination.nickname ? ` (${destination.nickname})` : ''),
          needsAddress: false,
        };
      },
    ) as NormalizedDestination[];

    const processedOwnRecipientDestinations = (recipientDestinationsData?.data || [])
      .map((destination) => {
        const isOwned = destination.customer_owned;
        if (isOwned) {
          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.billing_details.name,
            type: 'bank_account',
            alternateName: destination.display_name,
            lastFour: destination.payment_details.bank_account.mask,
            supportedNetworks: destination.payment_details.bank_account
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: destination.billing_details.name,
            needsAddress: destination.billing_details.address === null,
          };
        }
        return null;
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    const processedOwnDestinationsData = ownDestinationsData.data
      .map((destination) => {
        const type = destination.payment_method_detail.type as AccountType;

        if (type === 'debit_card') {
          destination = destination as DebitCardPaymentMethodCombined;

          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.payment_method_detail.description,
            alternateName: destination.payment_method_detail.expires,
            type: 'debit_card',
            lastFour: destination.payment_method_detail.last_four,
            brand: destination.payment_method_detail.brand,
            supportedNetworks: ['debit'],
            recipientName: null,
          };
        }
        return null;
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    return processedOwnDestinationsData
      .concat(processedZelleAccountDestinations)
      .concat(processedOwnRecipientDestinations);
  }, [ownDestinationsData?.data, instantPayoutMethodsData?.data, recipientDestinationsData?.data]);
  return (
    <>
      <SidebarHeader
        title={
          transferKind == 'own_transfer'
            ? 'Transfer funds to myself'
            : 'Transfer funds to someone else'
        }
        onClose={resetAndClose}
      />
      {transferKind === 'external_transfer' && (
        <TransferPanel
          availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], transferKind)}
          availableDestinations={normalizedExternalDestinations}
          loadingMethods={loadingMethods}
          loadingDestinations={loadingRecipientDestinations || loadingInstantPayoutMethodsData}
          reset={resetAndClose}
          onCompletedTransfer={onCompletedTransfer}
          walletBalance={walletBalance}
          transferKind="external_transfer"
          recipients={recipientDestinationsData?.data || []}
        />
      )}
      {transferKind === 'own_transfer' && (
        <TransferPanel
          availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], transferKind)}
          availableDestinations={normalizedOwnDestinations}
          loadingMethods={loadingMethods}
          loadingDestinations={loadingOwnDestinations || loadingRecipientDestinations}
          reset={resetAndClose}
          onCompletedTransfer={onCompletedTransfer}
          walletBalance={walletBalance}
          transferKind="own_transfer"
          recipients={recipientDestinationsData?.data || []}
        />
      )}
    </>
  );
}
