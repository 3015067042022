import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import StripeElementsWrapper from '@app/components/StripeElements/StripeElementsWrapper';
import StripeContext from '@app/contexts/stripeContext';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { useStripe } from '@stripe/react-stripe-js';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { AddFundsScenario } from './AddFunds';

export const ConfirmFunds = StripeElementsWrapper(
  ({
    onSuccess,
    onFailure,
    setLoading,
    scenario,
  }: {
    onSuccess: () => void;
    onFailure: (e: unknown) => void;
    setLoading: (loading: boolean) => void;
    scenario: AddFundsScenario;
  }): ReactElement => {
    const { clientSecret } = useContext(StripeContext);
    const stripe = useStripe();
    const [showRedirectionMessage, setShowRedirectionMessage] = useState(true);
    const isConfirmingRef = useRef(false);

    useEffect(() => {
      if (showRedirectionMessage) {
        return;
      }

      setLoading(true);
      const confirmFunds = async () => {
        try {
          const url = new URL(window.location.href);
          url.searchParams.set('payment_type', scenario);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const result = await stripe.confirmCardPayment(
            clientSecret,
            {
              return_url: url.toString(),
            },
            { handleActions: false },
          );
          const redirectUrl = result?.paymentIntent?.next_action?.redirect_to_url?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          if (result.error) {
            onFailure(result.error);
            return;
          }

          if (result.paymentIntent.status === 'succeeded') {
            onSuccess();
          }
        } catch (e: unknown) {
          onFailure(e);
        }

        setLoading(false);
      };

      if (!clientSecret || !stripe) {
        return;
      }

      if (!isConfirmingRef.current) {
        isConfirmingRef.current = true;
        void confirmFunds();
      }
    }, [clientSecret, stripe, showRedirectionMessage, onSuccess, onFailure, setLoading, scenario]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShowRedirectionMessage(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }, [showRedirectionMessage]);

    if (showRedirectionMessage) {
      return (
        <div>
          <div className="my-28 flex flex-col items-center text-center text-base font-medium">
            You should be redirected to confirm your payment in a few seconds.
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="mb-32 flex items-center text-base font-medium">
          <SpinnerBoundary />
        </div>
      </div>
    );
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.TREASURY_INTEGRATION_ENDPOINT,
);
