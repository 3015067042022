import { NavbarGroup } from '@app/interfaces/navbar';
import { NavbarOptionLink } from './NavbarOptionLink';

type NavbarSectionProps = {
  section: NavbarGroup;
};

export default function NavbarSection({ section }: NavbarSectionProps) {
  return (
    <div className="flex flex-col gap-2">
      {section.title && (
        <div className="flex items-center px-3">
          <p className="text-tertiary text-xs font-semibold uppercase tracking-[0.65px] md:tracking-[0.78px]">
            {section.title}
          </p>
        </div>
      )}
      <div className="flex flex-col">
        {section.options.map((option) => (
          <NavbarOptionLink key={option.key} option={option} />
        ))}
      </div>
    </div>
  );
}
