import Failure from '@app/components/layout/Failure';
import Success from '@app/components/layout/SuccessPage';
import StripeContext from '@app/contexts/stripeContext';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { AddFundsScenario } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import { addFundsScenarioToEventName } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFundsModal';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import Modal, { ModalFooter, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js/types/api/payment-intents';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StripeElementsWrapper from './StripeElementsWrapper';

export const usePaymentIntentQueryParams = () => {
  const params = new URLSearchParams(useLocation().search);
  const paymentIntentClientSecret = params.get('payment_intent_client_secret');
  const paymentType = params.get('payment_type') as AddFundsScenario;

  return { paymentIntentClientSecret, paymentType };
};

export default function Handle3DS(): ReactElement | null {
  const { paymentIntentClientSecret } = usePaymentIntentQueryParams();
  const { setClientSecret } = useContext(StripeContext);
  useEffect(() => {
    if (paymentIntentClientSecret) {
      setClientSecret(paymentIntentClientSecret);
    }
  }, [paymentIntentClientSecret, setClientSecret]);

  return paymentIntentClientSecret ? <WrappedPaymentIntentConfirmation /> : null;
}

const getDeclineMessage = (declineCode: string | undefined): string => {
  const declineMessages: Record<string, string> = {
    insufficient_funds:
      "Your card has insufficient funds. Please use a different card or add funds to your card's account.",
    processing_error: 'An error occurred while processing your card. Please try again later.',
    currency_not_supported:
      'Your card does not support this currency. Please try a different card.',
    card_not_supported:
      'Your card does not support this type of purchase. Please try a different card.',
    card_velocity_exceeded:
      'You have exceeded the balance, credit limit, or transaction amount limit available on your card. Please try a different card.',
    restricted_card:
      'Your card has restrictions that prevented this transaction. Please contact your bank.',
    authentication_required:
      'Your card requires additional authentication. Please try again and follow any prompts for verification. If the problem persists, please contact support.',
    withdrawal_count_limit_exceeded:
      'You have exceeded the withdrawal limit for this card. Please try a different card or contact your bank.',
  };

  return declineMessages[declineCode || ''] || '';
};

const getErrorMessage = (error: PaymentIntent.LastPaymentError): string => {
  const errorMessages: Record<string, string> = {
    card_declined: getDeclineMessage(error.decline_code),
    expired_card: 'Your card has expired. Please use a different card.',
    incorrect_cvc: "Your card's security code is incorrect. Please check and try again.",
    incorrect_number: 'Your card number is incorrect. Please check and try again.',
  };
  return errorMessages[error.code || ''] || '';
};

const addFundsScenarioToSuccessText: Record<AddFundsScenario, string> = {
  add_funds: 'The funds you added should arrive within 1-2 minutes.',
  account_setup_fee: 'Your setup fee has been paid. Your cards are on the way!',
  security_deposit:
    'Thank you for paying the deposit. Your new credit line will be available in a few minutes.',
};

function HandlePaymentIntentConfirmation(): ReactElement | null {
  const stripe = useStripe();
  const { paymentIntentClientSecret, paymentType } = usePaymentIntentQueryParams();
  const [status, setStatus] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);

  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  useEffect(() => {
    if (!paymentIntentClientSecret || !stripe) return;

    const confirmSuccess = async () => {
      try {
        const result = await stripe.retrievePaymentIntent(paymentIntentClientSecret);
        setModalOpen(true);
        if (result.paymentIntent?.status) {
          setStatus(result.paymentIntent.status);
          if (
            ['succeeded', 'processing'].includes(result.paymentIntent.status) &&
            Object.values(CustomerOnboardingEventName).includes(
              addFundsScenarioToEventName[paymentType],
            )
          ) {
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: addFundsScenarioToEventName[paymentType],
              },
            });
          } else if (result.paymentIntent.last_payment_error?.code) {
            setError(true);
            setErrorMessage(getErrorMessage(result.paymentIntent.last_payment_error));
          }
        }
      } catch (e: unknown) {
        setError(true);
      }
    };

    confirmSuccess();
  }, [stripe, paymentIntentClientSecret, createCustomerOnboardingEvent, paymentType]);

  const refreshPage = () => {
    window.location.href = window.location.href.split('?')[0];
  };

  if (status === 'succeeded' || status === 'processing') {
    return (
      <Modal toggle={refreshPage} open={modalOpen}>
        <ModalHeader title="Payment Successful" onClose={refreshPage} />
        <Success title="Success!" text={addFundsScenarioToSuccessText[paymentType]} />
        <ModalFooter>
          <div className="flex w-full justify-center md:justify-end">
            <Button color="primary" onClick={refreshPage}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  if (status === 'requires_payment_method' || error) {
    return (
      <Modal toggle={refreshPage} open={modalOpen}>
        <ModalHeader title="Payment Failed" onClose={refreshPage} />
        <Failure
          title="Your funds could not be added"
          text={
            errorMessage ||
            'We were unable to process your payment. Please try again. If the problem persists, please contact support.'
          }
        />
        <ModalFooter>
          <div className="flex w-full justify-end">
            <div>
              <Button color="primary" onClick={refreshPage}>
                Close
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  return null;
}

const WrappedPaymentIntentConfirmation = StripeElementsWrapper(
  HandlePaymentIntentConfirmation,
  ApiEndpoints.PAYMENTS_ENDPOINTS.TREASURY_INTEGRATION_ENDPOINT,
);
