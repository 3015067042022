import Checkbox from '@atob-developers/shared/src/components/Checkbox';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export interface UnlimitedToFlexMigrationAcceptOfferProps {
  creditLimit: number;
  onNext: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationAcceptOffer = ({
  creditLimit,
  onNext,
  onClose,
}: UnlimitedToFlexMigrationAcceptOfferProps): ReactElement | null => {
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);

  return (
    <>
      <div className="px-2 pt-2">
        <ModalHeader title="Congrats! You’re qualified for a credit line" onClose={onClose} />
        <ModalBodyContent overflowVisible={true}>
          <div className="flex flex-col text-base text-gray-700">
            <div className="xs:mb-4 my-2">
              Upgrade from your current prepaid card to a line of credit that can be used to
              purchase fuel in AtoB’s discount network. No credit check or application required.
            </div>

            <div className="mb-4 font-bold">Review Your Offer:</div>

            <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
              <div className="w-full px-4 pt-4">
                <div className="flex items-center md:flex-col md:items-start">
                  <h3 className="mr-2 text-2xl font-semibold">${creditLimit}</h3>
                  <div className="mt-2 text-sm font-semibold text-black">
                    Weekly credit line. Billed weekly
                  </div>
                </div>
              </div>
              <div className="mb-4 ml-4 flex flex-col items-start p-2">
                <ul className="list-disc p-1 text-sm">
                  <li>
                    Use your card at AtoB’s discount network stations like TA, Petro, TA Express,
                    Racetrack, Speedway, and more.
                  </li>
                  <li>
                    If you need to use your card outside AtoB’s discount network, there’s a $6
                    out-of-network fee per transaction.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex items-center">
              <div className="xs:mt-5 mt-1">
                <Checkbox
                  setChecked={() => {
                    setTosAccepted(true);
                  }}
                  checked={tosAccepted}
                  label="I accept the terms and conditions"
                  size="lg"
                  name="ul-to-flex-tos-accept"
                />
              </div>
            </div>
          </div>
        </ModalBodyContent>
      </div>
      <ModalFooter>
        <div className="flex w-full justify-center md:justify-end">
          <Button
            className="w-full sm:w-48"
            color="primary"
            onClick={onNext}
            disabled={tosAccepted === false}
          >
            Continue with Offer
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationAcceptOffer;
