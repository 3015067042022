import usePrepaidDetails from '@app/components/Prepaid/use-prepaid-details';
import { MiniSpacer, Spacer } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import CustomerContext from '@app/contexts/customerContext';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  faGaugeHigh,
  faInfinity,
  faWrench,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { ReactElement, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStripeLink } from '../Wallet/Onboarding';

interface UnlimitedBenefit {
  icon: IconDefinition;
  title: string;
  description: string;
}
const BenefitsConfig: UnlimitedBenefit[] = [
  {
    icon: faGaugeHigh,
    title: 'Build Credit',
    description: "Build your business's credit with every purchase.",
  },
  {
    icon: faInfinity,
    title: 'No Limit on Spend',
    description: 'Fund your Wallet, and spend as much as you want.',
  },
  {
    icon: faWrench,
    title: 'Non-fuel Expenses',
    description: 'Spend toward non-fuel vehicle and business expenses from day 1.',
  },
];

const StepsToUseCardConfig = [
  {
    title: 'Fund your AtoB Wallet',
    imgSrc: 'wallet-funding',
  },
  {
    title: 'Swipe & Pay',
    imgSrc: 'atob-card-spinning',
  },
  {
    title: 'Enable Auto-Deposit',
    imgSrc: 'wallet-auto-top-up',
  },
];

const GetUnlimitedButton = ({
  onClick,
  secondary,
  loading,
}: {
  onClick: () => void;
  secondary?: boolean;
  loading: boolean;
}): ReactElement => {
  return (
    <button
      className={classNames(
        'flex h-[56px] w-[75%] cursor-pointer items-center justify-center rounded-full px-4 py-2 text-base font-semibold md:w-[280px]',
        { 'bg-blue1 text-white': !secondary },
        { 'text-blue1 bg-white': secondary },
      )}
      onClick={onClick}
    >
      {loading ? <CircularProgress /> : 'Switch to AtoB Unlimited'}
    </button>
  );
};

const UnlimitedFeatures = ({ isPrepaid }: { isPrepaid: boolean }): ReactElement => {
  const { treasury } = useContext(CustomerContext).customer;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handlePremiumClick = () => {
    navigate('/premium');
  };

  const onRequestPrepaid = async () => {
    if (treasury.status === 'enrolled') {
      window.location.href = '/';
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await axios.post('/treasury/onboarding/enroll');
      const stripeLink = await getStripeLink();
      window.location.href = stripeLink;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setError((e as AxiosError).message);
    }
    setLoading(false);
  };

  const { onboardingState } = usePrepaidDetails({ treasury, isPrepaid });
  const optIn = onboardingState === 'optIn';

  return (
    <>
      <div className="bg-grey5 min-h-screen px-6 md:px-14">
        <div className="flex flex-col items-center text-center">
          <div className="bg-atob-card mt-[60px] min-h-[200px] w-[85%] flex-1 bg-contain bg-center bg-no-repeat md:w-[450px]" />
          <p className="text-ds-cool-gray-900 mt-10 w-[80%] text-3xl font-bold leading-[120%] md:mt-[72px]">
            Introducing AtoB Unlimited
          </p>
          <MiniSpacer />

          <p className="text-ds-cool-gray-500 px-4 text-base font-medium md:w-[70%]">
            Unlock added features for your fleet with Unlimited, AtoB's new pay-as-you-go plan.
          </p>
          <div className="m-4" />
          {optIn && (
            <>
              <GetUnlimitedButton onClick={onRequestPrepaid} loading={loading} />
              {error && <ErrorNotification error={error} className="mt-4 w-full" />}

              <MiniSpacer />
              <p className="text-ds-cool-gray-500 px-6 text-base">
                By switching you will no longer have access to a credit line.
              </p>
            </>
          )}
        </div>
        <div className="m-10 flex" />
        <div className="flex flex-wrap items-center gap-y-4">
          <div className="grid w-full grid-cols-1 grid-rows-3 gap-4 lg:grid-cols-3 lg:grid-rows-1">
            {BenefitsConfig.map((benefit, i) => (
              <BenefitCard key={i} benefit={benefit} />
            ))}
          </div>
          <div className="border-ds-bright-green mt-2 w-full border-l-[12px] bg-white p-6 md:min-w-full md:basis-1/2">
            <div className="items-center md:flex md:justify-between">
              <div>
                <p className="text-lg font-bold text-gray-700">
                  Free Premium features for 3 months
                </p>
                <p className="text-lg font-medium text-gray-500">
                  Enterprise-level card controls and fraud security.
                </p>
              </div>
              <MiniSpacer />
              <a
                className="text-lg font-medium text-gray-500 underline hover:text-black md:mr-4"
                onClick={handlePremiumClick}
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="m-12 flex" />
        <div className="flex flex-col items-center text-center">
          <p className="text-ds-cool-gray-900 text-3xl font-bold">Using your cards</p>
        </div>
        <div className="p-12" />
        <div className="flex flex-col lg:grid lg:grid-cols-3 lg:grid-rows-1 lg:items-start lg:gap-6">
          {StepsToUseCardConfig.map((step, stepNumber) => (
            <div
              key={stepNumber}
              className="lg:m-0 lg:mb-14 lg:flex lg:h-[400px] lg:flex-col lg:justify-between"
            >
              <div className="mb-8 flex flex-col items-center lg:text-center">
                <p className="mr-3 rounded-[50%] border-[2px] border-solid border-gray-700 px-2 py-3 text-2xl font-medium leading-[9px] text-gray-700">
                  {stepNumber + 1}
                </p>
                <div className="m-1" />
                <p className="text-2xl font-bold text-gray-900">{step.title}</p>
              </div>
              <div
                style={{ backgroundImage: `url(/src/assets/images/png/${step.imgSrc}.png)` }}
                className="min-h-[280px] bg-contain bg-center bg-no-repeat"
              />
            </div>
          ))}
        </div>
        <Spacer />
        <Spacer />
      </div>
      {optIn && (
        <div className="bg-ds-lp-base flex flex-col items-center px-6 text-center">
          <div className="m-8 flex" />
          <p className="px-6 text-3xl font-bold leading-[120%] text-white">
            How to switch to Unlimited
          </p>
          <div className="m-6 flex" />
          <Stepper />
          <div className="m-8 flex" />
          <GetUnlimitedButton onClick={onRequestPrepaid} secondary={true} loading={loading} />
          <div className="m-12 flex" />
        </div>
      )}
    </>
  );
};

const BenefitCard = ({ benefit }: { benefit: UnlimitedBenefit }): ReactElement => {
  return (
    <div className={classNames('w-full bg-white p-6 md:basis-1/3')}>
      <div>
        <FontAwesomeIcon icon={benefit.icon as IconLookup} size="2x" className="mb-6" />
        <p className="text-2xl font-bold text-gray-700">{benefit.title}</p>
        <MiniSpacer />
        <p className="text-lg font-medium text-gray-500">{benefit.description}</p>
      </div>
    </div>
  );
};

const ProductSwitchSteps = [
  {
    description: 'Click "Switch to AtoB Unlimited"',
  },
  {
    description: 'Create your AtoB Wallet by filling out a short form',
  },
  {
    description: 'Add funds to the Wallet via ACH or debit card',
  },
  {
    description: 'Pay off outstanding balance in full to complete transition to Unlimited.',
  },
];

const Stepper = (): ReactElement => {
  return (
    <div className="px-6">
      {ProductSwitchSteps.map((step, stepIdx) => (
        <div
          key={stepIdx}
          className={classNames(
            stepIdx !== ProductSwitchSteps.length - 1 ? 'pb-10' : '',
            'relative',
          )}
        >
          <>
            <div
              className={classNames('absolute left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300', {
                'h-[10%]': stepIdx === ProductSwitchSteps.length - 1,
              })}
              aria-hidden="true"
            />
            <span className="flex items-center">
              <span className="relative h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-white bg-white px-2">
                {stepIdx + 1}
              </span>
              <div className="ml-4 mr-8 text-left text-base font-medium leading-[150%] text-gray-400">
                {step.description}
              </div>
            </span>
          </>
        </div>
      ))}
    </div>
  );
};

export default UnlimitedFeatures;
