import { FlattenedPayrollData } from '@app/@types/payroll.types';
import Header from '@app/components/Navigation/Header';
import { ErrorNotification } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { sumPayrollPayment } from '@app/utils/payroll-itemization';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FORMATTED_LIMIT, PAYOUT_LIMIT_CENTS } from '../Table/PayAmountInput';
import {
  PaymentCalculationBody,
  PaymentCalculatorTotal,
  PaymentCalculationFooter,
  CalculationErrors,
  PaymentCalculation,
} from './PaymentCalculation';
import {
  PaymentItemizationBody,
  PayrollItemization,
  PayrollItemizationCategory,
  PayrollItemizationSubcategory,
} from './PaymentItemization';
export interface Props {
  driver: FlattenedPayrollData;
  takeStep: (_: 'back' | 'continue') => void;
  isContinueButtonDisabled: boolean;
  humanReadableError: string;
  paymentDetails: PaymentCalculation;
  setPaymentDetails: React.Dispatch<React.SetStateAction<PaymentCalculation>>;
  setCalculationErrors: React.Dispatch<
    React.SetStateAction<{
      [key in CalculationErrors]: string | null;
    }>
  >;
}

export const DesktopItemizationStep = (props: Props): ReactElement => {
  const {
    driver,
    takeStep,
    isContinueButtonDisabled,
    humanReadableError,
    paymentDetails,
    setPaymentDetails,
    setCalculationErrors,
  } = props;

  const [canDeleteItems, setCanDeleteItems] = useState(false);

  const handleItemAdd = (categories: {
    category: PayrollItemizationCategory;
    subcategory: PayrollItemizationSubcategory;
  }) => {
    setPaymentDetails((details) => {
      return {
        ...details,
        itemizations: [
          ...details.itemizations,
          {
            id: uuid(),
            amount_cents: 0,
            description: '',
            to_delete: false,
            ...categories,
          },
        ],
      };
    });
  };

  const handleUpdateCalculation = useCallback(
    (calculationDetails: Omit<PaymentCalculation, 'itemizations'>) => {
      setPaymentDetails((details) => {
        return { ...details, ...calculationDetails };
      });
    },
    [setPaymentDetails],
  );

  const handleUpdateItemization = useCallback(
    (itemization: PayrollItemization) => {
      setPaymentDetails((details) => {
        return {
          ...details,
          itemizations: [
            ...details.itemizations.map((item) =>
              item.id === itemization.id ? itemization : item,
            ),
          ],
        };
      });
    },
    [setPaymentDetails],
  );

  const handleUpdateError = useCallback(
    (key: CalculationErrors, value: string | null) => {
      setCalculationErrors((errors) => {
        return { ...errors, [key]: value };
      });
    },
    [setCalculationErrors],
  );

  const handleDeleteItems = (deleteInProgress: boolean) => {
    if (deleteInProgress) {
      setCanDeleteItems(true);
    } else {
      setPaymentDetails((details) => {
        return {
          ...details,
          itemizations: [...details.itemizations.filter((item) => !item.to_delete)],
        };
      });
      setCanDeleteItems(false);
    }
  };

  useEffect(() => {
    const total_cents = sumPayrollPayment(paymentDetails);
    if (total_cents >= PAYOUT_LIMIT_CENTS) {
      handleUpdateError('gross_total_limit', `Payment must be less than ${FORMATTED_LIMIT}`);
    } else {
      handleUpdateError('gross_total_limit', null);
    }
    if (total_cents < 0) {
      handleUpdateError('gross_total_below_zero', `Payment must be greater than $0`);
    } else {
      handleUpdateError('gross_total_below_zero', null);
    }
  }, [paymentDetails, handleUpdateError]);

  return (
    <PageContentWrapper
      header={
        <Header
          title="Calculate & Itemize"
          onBack={() => {
            takeStep('back');
          }}
        />
      }
    >
      <div>
        <div className="mb-2 text-xl font-bold leading-6 text-gray-900 sm:text-left">
          Calculate payment for {driver.first_name} {driver.last_name}
        </div>
        <div className="flex flex-col">
          {humanReadableError !== '' && (
            <ErrorNotification error={humanReadableError} className="w-full" />
          )}
          <span className="mt-4 text-base font-semibold">Gross Payment</span>
          <div className="flex items-baseline gap-x-4">
            <PaymentCalculationBody
              default_calculation_method={driver.last_paid_calculation_method}
              updateCalculation={handleUpdateCalculation}
              updateError={handleUpdateError}
            />
          </div>
          <PaymentItemizationBody
            addItem={handleItemAdd}
            itemizations={paymentDetails.itemizations}
            updateItemization={handleUpdateItemization}
            deleteEnabled={canDeleteItems}
          />
          <div className="mt-12">
            <div className="flex w-full justify-end gap-x-4 py-3">
              <PaymentCalculatorTotal paymentDetails={paymentDetails} />
            </div>
            <PaymentCalculationFooter
              isContinueButtonDisabled={isContinueButtonDisabled}
              confirmDescription={() => {
                takeStep('continue');
              }}
              canDeleteItems={canDeleteItems}
              deleteItems={handleDeleteItems}
            />
          </div>
        </div>
      </div>
    </PageContentWrapper>
  );
};
