import TagBadge from '@app/components/TagBadge/TagBadge';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
  getShortTimeZone,
} from '@atob-developers/shared/src/utils/formatters';
import { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CellPlaceholder } from './Cell';

// StandardColumns exports each column definition as an individual object to be used as mixins.
// Use this object to define the columns that should be displayed in the data grid.

export const monospacedStyle = {
  fontVariantNumeric: 'tabular-nums lining-nums',
};

const MoneyColumn: GridColTypeDef = {
  type: 'number',
  renderCell: (params) => {
    const { value } = params;

    if (value === null || value === undefined) return undefined;

    return <div style={monospacedStyle}>{formatCurrency({ value: value as number })}</div>;
  },
  minWidth: 100,
};

const CardNumberDisplay = ({ value }: { value: string }): ReactElement => (
  <div>
    <span className="select-none text-gray-400">····</span>{' '}
    <span style={monospacedStyle}>{value}</span>
  </div>
);

const CardColumn: GridColTypeDef = {
  type: 'number',
  sortable: false,
  align: 'left',
  headerAlign: 'left',
  renderCell: (params) => {
    const { value } = params;

    if (value === null || value === undefined) return undefined;

    return <CardNumberDisplay value={value} />;
  },
  minWidth: 100,
};

const DateAndTimeColumn = (
  headerPrefix = 'Date',
  minWidth = 130,
  showTimezone = true,
): GridColTypeDef => ({
  type: 'date',
  headerName: `${headerPrefix} ${showTimezone ? `(${getShortTimeZone()})` : ''}`,
  hideable: false,
  valueGetter: (value: string) => new Date(value),
  renderCell: (params) => {
    const { value } = params;

    if (value === null || value === undefined) {
      return <CellPlaceholder />;
    }

    const date = new Date(value);

    return (
      <div className="space-y-1 overflow-hidden">
        <p className="text-primary truncate text-xs font-medium">{formatDateLocale(date)}</p>
        <p className="text-secondary truncate text-xs">{formatTime(date)}</p>
      </div>
    );
  },
  minWidth: minWidth,
});

const DriverColumn: GridColTypeDef = {
  type: 'string',
  minWidth: 150,
  sortable: false,
};

const TagColumn: GridColTypeDef = {
  type: 'string',
  minWidth: 200,
  sortable: false,
  renderCell: (params) => {
    const { value } = params;

    if (value === null || value === undefined) return undefined;

    return (
      <div>
        <TagBadge tag={{ name: value, tag_id: value }} />
      </div>
    );
  },
};

const DefaultColumn: GridColTypeDef = {
  type: 'string',
  minWidth: 200,
  renderCell: (params) => {
    const { value } = params;

    if (value === null || value === undefined) return undefined;

    return <p className="truncate">{value}</p>;
  },
};

// For links to go to another page, the recommended verbage is "View".
// Otherwise prefer "Details".

const LinkAction = (
  getLink: (params: GridRenderCellParams) => string,
  buttonText: string,
): GridColTypeDef =>
  ({
    type: 'string',
    sortable: false,
    resizable: false,
    minWidth: 100,
    hideable: false,
    renderCell: (params) => {
      return (
        <div className="flex justify-end">
          <Link to={getLink(params)} className="text-blue-600">
            {buttonText}
          </Link>
        </div>
      );
    },
  }) as GridColTypeDef;

export {
  CardColumn,
  CardNumberDisplay,
  DateAndTimeColumn,
  DriverColumn,
  LinkAction,
  MoneyColumn,
  TagColumn,
  DefaultColumn,
};
