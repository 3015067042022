import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import { CustomerData } from '@app/@types/customer.types';
import { FilterDropdownInfiniteList } from '@app/components/Filters/FilterDropdownInfiniteList';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import { DescriptionInput } from '@app/pages/Wallet/WalletOverview/TransferFunds/Inputs';
import {
  formatAmountString,
  moneyFormat,
  parseAmountString,
} from '@app/pages/Wallet/WalletOverview/TransferFunds/utils';
import { apiGetFetcher, apiPostFetcher } from '@app/utils/data/fetchers';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faArrowUp, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Chip, Drawer, InputAdornment, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import { SetStateAction, useCallback, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { SWRInfiniteKeyLoader } from 'swr/infinite';
import useSWRMutation from 'swr/mutation';
import { Carrier } from '../types';
import { ConfirmationModal } from './Modals/ConfirmationModal';
import { SuccessModal } from './Modals/SuccessModal';

type RepaymentState = 'initial' | 'confirmation' | 'success';

export default function SingleRepaymentSidebar({
  open,
  reset,
  onSuccess,
}: {
  open: boolean;
  reset: () => void;
  onSuccess?: () => void;
}) {
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [amount, setAmount] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const [repaymentState, setRepaymentState] = useState<RepaymentState>('initial');

  const { addToast } = useToasts();

  const handleCancel = (): void => {
    reset();
    setCarrier(null);
    setDescription('');
    setAmount('');
    setReferenceId('');
    setRepaymentState('initial');
  };

  const { isMutating: isLoading, trigger } = useSWRMutation(
    {
      url: `partners/repayments`,
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          addToast(e.message, { appearance: 'error' });
        }
      },
      onSuccess: () => {
        setRepaymentState('success');
        mutate(CUSTOMER_QUERY_KEY);
        onSuccess?.();
      },
    },
  );

  const { data: customerData } = useSWR<EndpointResponse<CustomerData>>(
    carrier?.id ? `/partners/customers/${carrier.id}?include_account_balance=true` : null,
    apiGetFetcher,
  );

  const onSubmit = () => {
    if (repaymentState === 'initial') {
      setRepaymentState('confirmation');
      return;
    }
    trigger({
      customer_id: carrier?.id,
      amount_cents: parseAmountString(amount) * 100,
      reference_id: referenceId,
      description,
    });
  };

  return (
    <Drawer open={open} onClose={handleCancel} anchor="right">
      <div className="flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
        <SidebarHeader title="Single Repayment" onClose={handleCancel} />
        <>
          <div className="mb-[72px] flex-1">
            <div className="mb-12 flex flex-col gap-6 px-6 md:px-8">
              <CarrierSelector carrier={carrier} setCarrier={setCarrier} />
              <AmountInput
                amount={amount}
                setAmount={setAmount}
                setValidAmount={setValidAmount}
                balance={
                  (customerData?.data?.account_balance?.cents &&
                    moneyFormat(customerData.data.account_balance.cents / 100)) ||
                  null
                }
              />
              <ReferenceIdInput referenceId={referenceId} setReferenceId={setReferenceId} />
              <DescriptionInput description={description} setDescription={setDescription} />
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 w-full overflow-hidden bg-white">
            <div className="flex gap-3 px-6 pb-8 pt-4 md:px-8">
              <LoadingButton
                fullWidth
                onClick={onSubmit}
                startIcon={<FontAwesomeIcon icon={faArrowUp} />}
                disabled={!carrier || !referenceId || !amount || !validAmount}
                loading={isLoading}
              >
                Submit Balance Payment
              </LoadingButton>
            </div>
          </div>
        </>
        {repaymentState === 'confirmation' && carrier !== null && (
          <ConfirmationModal
            open
            onClose={() => setRepaymentState('initial')}
            onConfirm={onSubmit}
            loading={isLoading}
            amount={amount}
            description={description}
            recipientName={carrier?.company_name}
            referenceId={referenceId}
          />
        )}
        {repaymentState === 'success' && carrier !== null && (
          <SuccessModal
            open
            onClose={handleCancel}
            amount={amount}
            description={description}
            recipientName={carrier?.company_name}
            referenceId={referenceId}
          />
        )}
      </div>
    </Drawer>
  );
}

const ReferenceIdInput = ({
  referenceId,
  setReferenceId,
}: {
  referenceId: string;
  setReferenceId: (value: string) => void;
}) => {
  return (
    <div>
      <h4 className="mb-2 font-medium">Reference ID</h4>
      <TextField
        variant="outlined"
        fullWidth
        onChange={(e) => setReferenceId(e.target.value)}
        value={referenceId}
        placeholder="Enter Reference ID"
      />
    </div>
  );
};

const CarrierChip = ({ name, number }: { name: string; number: string | number | null }) => {
  return (
    <Chip
      color="grey"
      size="small"
      label={
        <span>
          {name} {number || '-'}
        </span>
      }
    />
  );
};

const CarrierSelector = ({
  carrier,
  setCarrier,
}: {
  carrier: Carrier | null;
  setCarrier: (value: Carrier | null) => void;
}) => {
  const selectedIds = carrier ? [carrier.id.toString()] : [];

  const keyFunc: (
    searchString: string,
  ) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<Carrier>> = useCallback(
    (searchString) => (index, previousPage) => {
      if (
        previousPage &&
        previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
      ) {
        return null;
      }
      return {
        url: '/partners/customers',
        params: {
          page: index + 1,
          per: 10,
          query: searchString,
          all: false,
        },
      };
    },
    [],
  );

  return (
    <div>
      <h4 className="mb-2 flex">Select a carrier</h4>
      <FilterDropdownInfiniteList
        name="carrier"
        fullWidth
        size="medium"
        selectClassname="h-auto"
        onSelect={() => {}}
        multiselect={false}
        keyFunc={keyFunc}
        selectedIds={selectedIds}
        searchPlaceholder="Search by name, ID, DOT"
        renderListItem={(item) => {
          return (
            <MenuItem key={item.id} value={item.id} onClick={() => setCarrier(item)}>
              <div className="align-center flex gap-2">
                <span className="text-secondary self-center text-sm font-medium">
                  {item.company_name}
                </span>
                <CarrierChip name="ID" number={item.id} />
                <CarrierChip name="DOT" number={item.dot_number} />
              </div>
            </MenuItem>
          );
        }}
        onReset={() => setCarrier(null)}
        renderValue={() => {
          if (carrier === null) return <div>Select a carrier</div>;
          return (
            <div className="flex gap-3">
              <div className="bg-level-1 flex h-[40px] w-[40px] justify-center self-center rounded-full text-center">
                <span className="text-secondary self-center text-base font-semibold">
                  {carrier?.company_name
                    .split(/\s+/)
                    .map((w) => w[0].toUpperCase())
                    .join('')
                    .slice(0, 2)}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-secondary text-sm font-medium">{carrier?.company_name}</p>
                <div className="flex gap-2">
                  <CarrierChip name="ID" number={carrier?.id} />
                  <CarrierChip name="DOT" number={carrier?.dot_number} />
                  <CarrierChip name="EIN" number={carrier?.ein} />
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

const AmountInput = ({
  amount,
  setAmount,
  balance,
  setValidAmount,
}: {
  amount: string;
  setAmount: (value: SetStateAction<string>) => void;
  balance: string | null;
  setValidAmount: (value: SetStateAction<boolean>) => void;
}) => {
  const balanceAmount = parseAmountString(balance || '0');
  const requestedAmount = parseAmountString(amount);

  const invalidAmount = balanceAmount < requestedAmount;

  return (
    <div>
      <h4 className="mb-2 font-medium">Amount</h4>
      <div className="flex flex-col gap-2">
        <TextField
          error={invalidAmount}
          onChange={(e) => {
            const formattedAmount = formatAmountString(e.target.value);
            setValidAmount(parseAmountString(formattedAmount) <= balanceAmount);
            setAmount(formattedAmount);
          }}
          value={amount}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faDollarSign} />
                </InputAdornment>
              ),
            },
          }}
          placeholder="0.00"
          helperText={
            invalidAmount &&
            'This amount exceeds the maximum transferable amount. Please pick a lower amount.'
          }
        />
        {balance && (
          <div className="text-secondary text-sm">
            Current balance due:{' '}
            <span
              className="cursor-pointer underline"
              onClick={() => setAmount(balanceAmount.toString())}
            >
              {moneyFormat(balanceAmount)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
