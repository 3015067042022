import { EndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import useSWRImmutable from 'swr/immutable';
import { MarketingBanner } from './Designs/MarketingBanner';

type FundboxOfferData = {
  amount: number;
  link: string;
  expiration_date: string;
  hide_banner: boolean;
};

const FundboxOfferBanner = (): ReactElement | null => {
  const { data: fundboxOffer, isLoading: loading } = useSWRImmutable<
    EndpointResponse<FundboxOfferData>
  >({ url: `/fundbox_offers` }, apiGetFetcher);

  if (loading || !fundboxOffer?.data || fundboxOffer.data.hide_banner) return null;

  return (
    <MarketingBanner
      onClick={() => window.open(fundboxOffer.data.link, '_blank', 'noopener,noreferrer')}
      actionText="Continue with Offer"
      bannerKey="fundbox-offer-banner"
      title="Offer"
      nonDismissable={true}
      icon={faPartyHorn}
    >
      You are pre-approved for an extra business line of credit of ${fundboxOffer.data.amount}!
      Offer valid until {fundboxOffer.data.expiration_date}.
    </MarketingBanner>
  );
};

export default FundboxOfferBanner;
