import { ReactNode } from 'react';

type RoundedCardTitleProps = {
  title: string;
  shortDescription?: string;
  chip?: ReactNode;
};

export default function RoundedCardTitle({ title, shortDescription, chip }: RoundedCardTitleProps) {
  return (
    <div className="border-level-2 flex items-center justify-between gap-2 border-b px-4 py-3 md:px-6 md:py-4">
      <h2 className="text-primary text-sm font-semibold md:text-base">{title}</h2>
      <div className="flex items-center gap-2">
        {shortDescription && (
          <span className="text-secondary text-xs md:text-sm">{shortDescription}</span>
        )}
        {chip}
      </div>
    </div>
  );
}
