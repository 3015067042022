import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import useWindowWidth from '@app/hooks/useWindowWidth';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Link } from '@mui/material';
import Button from '@mui/material/Button';
import { PropsWithChildren, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

export type TitleVariant =
  | 'Pro Tip'
  | 'Product Tour'
  | 'New Product'
  | 'New Feature'
  | 'New Discount'
  | 'Attention'
  | 'Offer'
  | 'Limited time offer'
  | 'Limited time welcome bonus';

export interface MarketingBannerProps {
  bannerKey: string;
  title?: string;
  icon?: IconProp;
  iconImg?: ReactNode;
  onClick?: () => void;
  actionText?: string;
  onLinkClick?: () => void;
  linkText?: string;
  nonDismissable?: boolean;
}

export const MarketingBanner = ({
  bannerKey,
  title,
  onClick,
  actionText,
  onLinkClick,
  linkText,
  nonDismissable = false,
  icon,
  iconImg,
  children,
}: PropsWithChildren<MarketingBannerProps>) => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);
  const { isTablet } = useWindowWidth();

  const { hideDismissableBanners } = useThemeConfiguration();
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="bg-marketing-banner relative flex w-full flex-col items-start gap-3 rounded-lg p-4 xl:shrink-0 xl:flex-row xl:items-center xl:justify-between xl:gap-0"
    >
      <div className="flex items-start gap-2 self-stretch xl:items-center xl:self-auto">
        {iconImg ? (
          <div className="h-8 w-8 xl:h-10 xl:w-10">{iconImg}</div>
        ) : icon ? (
          <div className="border-dark-level-2 bg-dark-level-0 flex h-8 w-8 items-center justify-center rounded-full border xl:h-10 xl:w-10">
            <FontAwesomeIcon className="h-4 w-4 text-white xl:h-6 xl:w-6" icon={icon} />
          </div>
        ) : (
          <span className="h-6 rounded border border-white px-2 py-1 text-xs uppercase text-white">
            New
          </span>
        )}
        <div className="flex flex-1 shrink-0 flex-col items-start gap-0.5 xl:gap-0">
          {title && (
            <span className="text-dark-tertiary text-[10px] font-semibold uppercase leading-4 tracking-[0.65px] xl:text-xs xl:tracking-[0.78px]">
              {title}
            </span>
          )}
          <span className="text-dark-primary text-xs font-medium leading-5 xl:text-sm">
            {children}
          </span>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-2 self-stretch xl:self-auto">
        {actionText && onClick && (
          <Button
            size={isTablet ? 'small' : 'extra-small'}
            color="primary"
            className="bg-dark-accent-21 text-dark-background flex-1 xl:flex-none"
            onClick={() => {
              logger.info('banner-cta-clicked', { key: bannerKey });
              onClick();
            }}
          >
            {actionText}
          </Button>
        )}
        {linkText && onLinkClick && (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              logger.info('banner-link-clicked', { key: bannerKey });
              onLinkClick();
            }}
            className="flex-1 bg-opacity-90 text-white decoration-white hover:bg-opacity-90 hover:text-white"
          >
            {linkText}
          </Link>
        )}
        {!nonDismissable && (
          <>
            <Button
              size={isTablet ? 'small' : 'extra-small'}
              className="text-level-0 bg-dark-accent-23 flex-1 backdrop-blur-md xl:hidden"
              onClick={() => {
                logger.info('banner-dismissed', { key: bannerKey });
                setDismissed();
              }}
            >
              Dismiss
            </Button>
            <IconButton
              size={isTablet ? 'small' : 'extra-small'}
              className="text-level-0 bg-dark-accent-23 hidden backdrop-blur-md xl:flex"
              onClick={() => {
                logger.info('banner-dismissed', { key: bannerKey });
                setDismissed();
              }}
            >
              <FontAwesomeIcon className="h-5 w-5 text-white" icon={faXmark} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};
