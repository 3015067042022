import { Address } from '@app/interfaces/address';
import { addressValidationSchema } from '@app/utils/validation/debit-card-validation';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import DebitSaveSuccessful from '../PaymentMethods/DebitSaveSuccessful';
import BillingAddressForm from './BillingAddressForm';
import './SetupDebitCardWithVGS.css';
import { useDebitCardVGSForm } from './useDebitCardVgsForm';

const VGSInputField = ({
  id,
  label,
  error,
}: {
  id: string;
  label: string;
  error: string | null;
}) => {
  return (
    <label className="flex w-full flex-col gap-2">
      <span
        className={classNames('text-base font-medium', {
          'text-error-1': error,
          'text-primary': !error,
        })}
      >
        {label}
      </span>
      <div className="form-field h-12" id={id} />
      <span className="text-error-1 text-sm">{error}</span>
    </label>
  );
};

const SetupDebitCardWithVGS = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
}): ReactElement => {
  const [address, setAddress] = useState<Address>({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });

  const [addressErrors, setAddressErrors] = useState<Record<string, string | null>>({});

  const {
    loading,
    handleVgsSubmit,
    response,
    form,
    vgsErrors,
    setVgsErrors,
    showAllVGSErrors,
    fieldsLoading,
    loadForm,
  } = useDebitCardVGSForm({ address, onSuccess, onError });

  const getAddressFieldError = useCallback(async (field: keyof Address, value: string) => {
    try {
      await addressValidationSchema.validateAt(field, { [field]: value });
      return undefined;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        return err.message;
      }
      return undefined;
    }
  }, []);

  const validateAddressField = useCallback(
    async (field: keyof Address, value: string) => {
      const error = await getAddressFieldError(field, value);
      setAddressErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (error) {
          newErrors[field] = error;
        } else {
          const { [field]: _, ...rest } = newErrors;
          return rest;
        }
        return newErrors;
      });
    },
    [getAddressFieldError],
  );

  const hasLoadedForm = useRef(false);

  useEffect(() => {
    if (!hasLoadedForm.current) {
      hasLoadedForm.current = true;
      loadForm();
    }
  }, [loadForm]);

  const hasErrors = (obj: { [key: string]: string | null }) => Object.keys(obj).length > 0;

  const handleSubmit = async () => {
    const newAddressErrors: Record<string, string | null> = {};

    await Promise.all(
      Object.entries(address).map(async ([key, value]) => {
        const error = await getAddressFieldError(key as keyof Address, value);
        if (error) {
          newAddressErrors[key] = error;
        }
      }),
    );

    const newVGSErrors = showAllVGSErrors();

    setVgsErrors(newVGSErrors);
    setAddressErrors(newAddressErrors);

    if (hasErrors(newVGSErrors) || hasErrors(newAddressErrors)) {
      return;
    }

    handleVgsSubmit();
  };

  if (response) {
    return (
      <div>
        <DebitSaveSuccessful />
      </div>
    );
  }

  const formLoading = !form || fieldsLoading;

  return (
    <>
      {formLoading && (
        <div className="flex h-48 items-center justify-center">
          <CircularProgress />
        </div>
      )}
      <div
        className={classNames('mb-4 flex flex-col overflow-hidden', {
          hidden: formLoading,
        })}
      >
        <form id="vgs-collect-form" data-testid="vgs-collect-form" className="flex flex-col gap-2">
          <VGSInputField
            id="vgs-cardholder-name"
            label="Cardholder name"
            error={vgsErrors['vgs-cardholder-name']}
          />
          <VGSInputField
            id="vgs-card-number"
            label="Debit card number"
            error={vgsErrors['vgs-card-number']}
          />
          <div className="flex gap-3">
            <VGSInputField
              id="vgs-card-expiry"
              label="Expiration"
              error={vgsErrors['vgs-card-expiry']}
            />
            <VGSInputField id="vgs-card-cvc" label="CVC" error={vgsErrors['vgs-card-cvc']} />
          </div>
          <BillingAddressForm
            address={address}
            setAddress={setAddress}
            errors={addressErrors}
            validateField={validateAddressField}
          />
        </form>
        <LoadingButton
          className="mt-6 p-3"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          loading={loading}
          onClick={handleSubmit}
        >
          Add debit card
        </LoadingButton>
      </div>
    </>
  );
};

export default SetupDebitCardWithVGS;
