import { createContext, useContext } from 'react';
import { TransferKind } from '../utils';

export enum WalletTransferStep {
  TransferType = 0,
  TransferDetails = 1,
}

export const WalletTransferContext = createContext<{
  step: WalletTransferStep;
  goNextStep: (s?: WalletTransferStep) => void;
  goPrevStep: (s?: WalletTransferStep) => void;
  setTransferKind: (type: TransferKind) => void;
  transferKind: TransferKind | null;
  resetAndClose: () => void;
  onCompletedTransfer: () => void;
  walletBalance: string | null | undefined;
}>({
  step: WalletTransferStep.TransferType,
  goNextStep: () => {},
  goPrevStep: () => {},
  setTransferKind: (_) => {},
  transferKind: null,
  resetAndClose: () => {},
  onCompletedTransfer: () => {},
  walletBalance: '$0.00',
});

export function useWalletTransfer() {
  const transferContext = useContext(WalletTransferContext);
  if (!transferContext) {
    throw new Error('useWalletTransfer must be used within WalletTransferContext');
  }
  return transferContext;
}
