import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { Button, Dialog, DialogContent } from '@mui/material';
import { ReactElement, useState } from 'react';
import { MarketingBanner } from './Designs/MarketingBanner';

const AmountApplicationBanner = (): ReactElement | null => {
  const [showModal, setShowModal] = useState(false);
  const [showAmountBanner] = useFeatureFlags('amount_rollout');

  if (!showAmountBanner) {
    return null;
  }

  return (
    <>
      <MarketingBanner
        onClick={() => setShowModal(true)}
        actionText="Get Started"
        bannerKey="amount-application-banner"
        title="New Product"
      >
        Launching our new Line of Credit solution to help you grow your business. Receive approval
        for up to $100k in credit with best-in-market financing rates.
      </MarketingBanner>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <div className="flex items-center bg-white p-2 pb-8">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold text-gray-900">
                Flexible financing built specifically for your fleet
              </p>
              <p className="text-base text-gray-900">
                Get access to funds when you need them – supercharging your business’s growth and
                ensuring your longevity. Fill out the 5 min application below to receive your
                financing offer.
              </p>
              <div className="flex flex-col gap-2 text-base">
                <p>📈 12-24 month lines up to $100k</p>
                <p>🔐 Funds available in AtoB wallet within 2 days of approval</p>
                <p>💵 Best in-market rates for financing</p>
                <p>✔️ Only pay for funds your fleet uses</p>
              </div>
            </div>
          </div>
          <Button
            className="w-full"
            size="large"
            color="primary"
            href={'https://secure.fundation.com/AtoB/Home'}
            target="_blank"
          >
            Apply Now
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AmountApplicationBanner;
