import { EndpointResponse } from '@app/@types/api.types';
import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import { ModalOptionData } from '@app/components/Modals/ModalOptions/ModalOptions';
import { SecurityDeposit } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferModal';
import { apiPostFetcher, FetcherKey } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import useSWRMutation from 'swr/mutation';

type SecurityDepositProps = {
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
};

export default function useSecurityDeposit({
  offerDetails,
  refetchOfferDetails,
}: SecurityDepositProps) {
  const { addToast } = useToasts();
  const fixedOffer = offerDetails?.offer_type === 'fixed';
  // in the fixed offer flow, the CL and deposit amount are fixed. For the variable flow, they are user editable.
  const [desiredCreditLine, setDesiredCreditLine] = useState<number>(
    fixedOffer ? offerDetails?.credit_limit || 1000 : 0,
  );
  const [selectedOption, setSelectedOption] = useState<'security_deposit'>('security_deposit');
  const [securityDepositModalOpen, setSecurityDepositModalOpen] = useState<boolean>(false);

  const depositAmount = fixedOffer
    ? offerDetails.deposit_amount || 300
    : (desiredCreditLine * (offerDetails?.deposit_percentage || 30)) / 100;

  const options: ModalOptionData[] = [
    {
      id: 'security_deposit',
      heading: <div className="text-2xl">${desiredCreditLine}</div>,
      subheading: 'Weekly credit line. Billed weekly.',
      description: [
        <div key="deposit_amount">
          Make a refundable deposit of <b>${depositAmount}</b>
        </div>,
        'Credit Line is available in 5 mins if debit card is used to make the deposit.',
        "After 1 month of $2500 or more in spend and on-time payments, you're eligible to request a full refund of your deposit",
        offerDetails?.offer_open
          ? 'Your cards will be shipped upon accepting the offer below.'
          : '',
      ].filter((x) => x !== ''),
    },
  ];

  const { trigger: confirmSecurityDepositNetNew, isMutating: isConfirmSecurityDepositNetNew } =
    useSWRMutation<
      EndpointResponse<Record<string, string>>,
      AxiosError,
      FetcherKey,
      Record<string, string>
    >(
      {
        url: '/customer/enroll_security_deposit_net_new',
      },
      apiPostFetcher,
      {
        onError: (e) => {
          if (guardAxiosError(e)) {
            addToast(
              'Something went wrong! Please try again or contact support if the issue persists',
              { appearance: 'error' },
            );
            Sentry.captureException(e);
          }
        },
      },
    );

  const { trigger: upsertSecurityDeposit, isMutating: isUpdatingSecurityDeposit } = useSWRMutation<
    EndpointResponse<SecurityDeposit>,
    AxiosError,
    FetcherKey,
    { credit_limit_cents: number; amount_cents: number }
  >(
    {
      url: '/security_deposits/create_deposit_for_net_new',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          const errorMessage = e.response?.data?.error
            ? `${e.response.data.error}.`
            : 'Something went wrong! Please try again or contact support if the issue persists';
          addToast(errorMessage, { appearance: 'error' });
          Sentry.captureException(e);
        }
      },
      onSuccess: () => {
        refetchOfferDetails();
        setSecurityDepositModalOpen(true);
      },
    },
  );

  const handleContinue = async () => {
    try {
      if (offerDetails?.offer_open) {
        await confirmSecurityDepositNetNew({});
      }

      if (!fixedOffer) {
        await upsertSecurityDeposit({
          credit_limit_cents: desiredCreditLine * 100,
          amount_cents: depositAmount * 100,
        });
      }

      refetchOfferDetails();
      setSecurityDepositModalOpen(true);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return {
    securityDepositModalOpen,
    setSecurityDepositModalOpen,
    fixedOffer,
    desiredCreditLine,
    setDesiredCreditLine,
    options,
    selectedOption,
    setSelectedOption,
    isLoading: isUpdatingSecurityDeposit || isConfirmSecurityDepositNetNew,
    handleContinue,
  };
}
