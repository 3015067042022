import { PartnerLogo } from '../layout/PartnerLogo';

type HeaderProps = {
  title: string;
  description?: string;
};

export default function Header({ title, description }: HeaderProps) {
  return (
    <div className="flex flex-col items-start justify-end gap-6">
      <PartnerLogo className="h-7 w-[88px] md:hidden" />
      <div className="flex flex-col gap-2 md:gap-4 md:pt-6">
        <h3 className="text-primary text-3xl font-semibold tracking-[-0.75px] md:text-[32px] md:tracking-[-0.80px]">
          {title}
        </h3>
        {description && <p className="text-secondary text-sm md:text-base">{description}</p>}
      </div>
    </div>
  );
}
