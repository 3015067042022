import StatusCodes from '@app/constants/http-status-codes';
import useAuth from '@app/hooks/useAuth';
import logger from '@app/utils/datadog-logger';
import { getEnvironment } from '@app/utils/environment';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { useCallback, useState } from 'react';
import { Address } from '../elements/AddressInput';
import { Data, Field, FieldConfig, FieldState, Form, VGSCollect, VGSFormValues } from './types';
import { cssWithWidth } from './utils';

export const useDebitCardVGSForm = ({
  address,
  onSuccess,
  onError,
}: {
  address: Address;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { getAccessTokenSilently } = useAuth();
  const { addToast } = useToasts();

  const { VITE_VGS_VAULT_ID, VITE_VGS_ENVIRONMENT } = getEnvironment();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const [form, setForm] = useState<Form | null>(null);
  const [vgsErrors, setVgsErrors] = useState<Record<string, string | null>>({});
  const [fields, setFields] = useState<FieldConfig[]>([]);
  const [fieldsLoading, setFieldsLoading] = useState(false);

  const initForm = useCallback((vgsCollect: VGSCollect) => {
    setFieldsLoading(true);
    const vgsForm = vgsCollect.init((_: unknown) => {});
    const fieldsConfig = [
      {
        id: 'vgs-cardholder-name',
        field: {
          type: 'text',
          name: 'card_name',
          placeholder: 'ex. Monica Johnson',
          validations: ['required'],
          css: cssWithWidth('100%'),
        },
        title: 'Cardholder name',
      },
      {
        id: 'vgs-card-number',
        field: {
          type: 'card-number',
          name: 'card_number',
          placeholder: 'ex. 1234 5678 8909 8765',
          showCardIcon: {
            right: '12px',
          },
          validations: ['required', 'validCardNumber'],
          autoComplete: 'cc-number',
          css: cssWithWidth('100%'),
        },
        title: 'Debit card number',
      },
      {
        id: 'vgs-card-expiry',
        field: {
          type: 'card-expiration-date',
          name: 'card_exp',
          placeholder: 'MM/YY',
          yearLength: '2',
          validations: ['required', 'validCardExpirationDate'],
          autoComplete: 'cc-exp',
          css: cssWithWidth('100%'),
        },
        title: 'Expiration',
      },
      {
        id: 'vgs-card-cvc',
        field: {
          type: 'card-security-code',
          name: 'card_cvc',
          placeholder: '000',
          validations: ['required', 'validCardSecurityCode'],
          css: cssWithWidth('100%'),
        },
        title: 'CVC',
      },
    ];

    const vgsFields = fieldsConfig.map(({ id, field, title }) => ({
      id,
      title,
      field: vgsForm.field(`#${id}`, field),
    }));

    const cardNumberField = vgsFields.find((f) => f.id === 'vgs-card-number')?.field;
    const cvcField = vgsFields.find((f) => f.id === 'vgs-card-cvc')?.field;

    if (cardNumberField && cvcField) {
      cardNumberField.setCVCDependency(cvcField);
    }

    const promises = vgsFields.map((item) => item.field.promise);

    Promise.all(promises)
      .then(() => {
        setFieldsLoading(false);
      })
      .catch((e) => {
        logger.error('Error when loading VGS fields', e);
        setFieldsLoading(false);
      });

    setForm(vgsForm);
    setFields(vgsFields);

    vgsFields.forEach(({ id, field, title }) => {
      field.on('update', (state: FieldState) => {
        if (state.isTouched) {
          const error = `${title} ${state.errorMessages[0] || ''}`;
          setVgsErrors((prev) => {
            const newErrors = { ...prev };
            if (state.isValid) {
              const { [id]: _, ...rest } = newErrors;
              return rest;
            } else {
              newErrors[id] = error;
              return newErrors;
            }
          });
        }
      });
    });
  }, []);

  const showAllVGSErrors = () => {
    const fieldsToFocus: Field[] = [];
    const errors: Record<string, string | null> = {};

    fields.forEach(({ field, id, title }) => {
      const state = form?.state[field.name] as FieldState | undefined;
      const isValid = state?.isValid;
      const isTouched = state?.isTouched;
      const errorMsg = state?.errorMessages?.[0];
      if (!isValid && errorMsg) errors[id] = `${title} ${errorMsg}`;
      if (!isValid && !isTouched) {
        fieldsToFocus.push(field);
      }
    });

    fieldsToFocus.forEach((field) => {
      field.focus();
    });

    if (fieldsToFocus.length > 0) {
      fieldsToFocus[0].focus();
    }

    return errors;
  };

  const handleVgsSubmit = async () => {
    setLoading(true);

    const authToken = await getAccessTokenSilently();

    form?.submit(
      '/vgs/payment_methods',
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: (formValues: VGSFormValues) => {
          return {
            cardholder_name: formValues.card_name,
            card_number: formValues.card_number,
            card_exp: formValues.card_exp,
            card_cvc: formValues.card_cvc,
            card_address_zip: address.zip,
            address: {
              address1: address.address1,
              address2: address.address2 ?? '',
              city: address.city,
              state: address.state,
              zip: address.zip,
            },
          };
        },
      },
      (status: StatusCodes, data: Data) => {
        setLoading(false);
        if (status !== StatusCodes.CREATED) {
          const defaultError =
            'There was a problem adding your debit card. Please try again later, or if the issue persists, contact support.';
          const [error] = data.errors || [defaultError];
          addToast(error, { appearance: 'error' });
        } else {
          setResponse(true);
          onSuccess();
        }
      },
      (e: Error) => {
        logger.error('Error submitting form to VGS', e);
        setLoading(false);
      },
    );
  };

  const loadForm = async () => {
    try {
      const vgsCollect = await loadVGSCollect({
        vaultId: VITE_VGS_VAULT_ID as string,
        environment: VITE_VGS_ENVIRONMENT as string,
        version: '2.18.1',
      });
      initForm(vgsCollect as VGSCollect);
    } catch (e: unknown) {
      onError?.();
      addToast(
        'We were unable to load the card details form. Please try again. If the error persists, please contact our support team.',
        { appearance: 'error' },
      );
      Sentry.captureMessage('Error setting up VGS Collect');
      setFieldsLoading(false);
    }
  };

  return {
    handleVgsSubmit,
    loading,
    response,
    form,
    vgsErrors,
    setVgsErrors,
    showAllVGSErrors,
    fieldsLoading,
    loadForm,
  };
};
