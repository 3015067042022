import { FuelGrade, MyLocation, PlaceType } from '@app/@types/fuel_listings.types';
import FuelDiscountPromotionalBanner from '@app/components/Banner/FuelDiscountPromotionalBanner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { useFuelMap } from '@app/pages/FuelMap/useFuelMap';
import { getFetcher } from '@app/utils/data/fetchers';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { FormControl, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { MarketingBanner } from '../Banner/Designs/MarketingBanner';
import MarketingBannerComponent from '../Banner/MarketingBanners';
import DiscountBoostToggle from './DiscountBoostToggle';
import DiscountNetworkSelect from './DiscountNetworkSelect';
import DriverRewardedStationsToggle from './DriverRewardedStationsToggle';
import { OriginEndAdornments, OriginStartAdornments } from './FuelListingAdornments';
import './FuelListings.css';
import FuelListingsList from './FuelListingsList';
import GoogleAutocomplete from './GoogleAutocomplete';
import GradeSelect from './GradeSelect';
import RadiusSelect, { RadiusOption } from './RadiusSelect';
import SortSelect from './SortSelect';

const EMPTY_LOCATION: MyLocation = {
  location_type: 'my_location',
  place_id: 'empty',
  address_components: [],
  formatted_address: '',
  description: '',
  structured_formatting: {
    main_text: '',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
};

const FuelListings = () => {
  const {
    addressCoords,
    setAddressCoords,
    selectedGrade,
    setSelectedGrade,
    placesService,
    setMapCenterCoords,
    setOriginAddressIsEligibleForMarker,
    destinationCoords,
    setDestinationCoords,
    address,
    setAddress,
    setFitBounds,
    setShowMoreButton,
    routing,
    setRouting,
    setFilterHighFlowDieselLanes,
    selectedRadius,
    setSelectedRadius,
    routingDisplayRadius,
    setRoutingDisplayRadius,
    selectedSort,
    setSelectedSort,
    discountOnly,
    setDiscountOnly,
    userLocation,
    setSelectedFuelListingId,
  } = useFuelMapContext();

  const navigate = useNavigate();

  const { isFetching, filteredFuelData, defaultRouteRadius, onSelectFuelListing } = useFuelMap();
  const [challengeMatch, costPlusDriverCashback] = useProduct(
    'challenge_match',
    'cost_plus_driver_cashback',
  );
  const [disableDiscountNetworkSelect, comeBack10cpg, preApproved10cpg] = useFeatureFlags(
    'fuel_map_discounts_only',
    'come_back_10_cpg',
    'ten_cpg_discount_boost_preapproval',
  );

  const [destinationAddress, setDestinationAddress] = useState<PlaceType | MyLocation>(
    EMPTY_LOCATION,
  );

  // Preload
  useSWR({ url: '/drivers/counts' }, getFetcher);

  const onSelectGrade = (grade: FuelGrade | 'TRUCK DIESEL') => {
    setSelectedGrade(grade);
    setFilterHighFlowDieselLanes(grade === 'TRUCK DIESEL');
  };

  const onSelectRadius = (radius: RadiusOption) => {
    setSelectedRadius(radius);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="border-grey16 hidden border-b px-6 py-4 text-base font-semibold md:block">
        Fuel Map
      </div>
      <div className="px-6 pt-2 md:pt-0">
        <FuelDiscountPromotionalBanner />
        <MarketingBannerComponent />
      </div>
      {comeBack10cpg && (
        <div className="px-6 pt-2 md:pt-0">
          <MarketingBanner
            bannerKey="q4_10cpg_incentive"
            title="Pro Tip"
            actionText="Learn More"
            onClick={() => setShowModal((p) => !p)}
          >
            Congrats! Your fuel discounts have been upgraded. Enjoy an additional 10¢/gal on fuel
            transactions at all stations in the AtoB discount network
          </MarketingBanner>
          <Modal open={showModal} toggle={() => setShowModal((p) => !p)}>
            <ModalHeader title="How it works" onClose={() => setShowModal((p) => !p)} />
            <ModalBodyContent>
              <ol className="list-decimal">
                <li className="mb-4">
                  Use your fuel card at qualifying stations nationwide, like TA, Petro, TA Express,
                  Speedway, and RaceTrac, and receive an extra 10¢/gal in savings, on top of our
                  regular fuel discounts.
                </li>
                <li>
                  Your extra 10¢/gal savings will be added as a statement credit to your account
                  monthly. This offer will apply to all eligible fuel transactions from now until
                  February 10th!
                </li>
              </ol>
            </ModalBodyContent>
          </Modal>
        </div>
      )}
      {preApproved10cpg && (
        <div className="px-6 pt-2 md:pt-0">
          <MarketingBanner
            bannerKey="q4_10cpg_incentive"
            title="Pro Tip"
            actionText="Learn More"
            onClick={() => setShowModal((p) => !p)}
          >
            Congrats! Enjoy an additional 10¢/gal savings on all fuel purchases from stations in the
            AtoB discount network.
          </MarketingBanner>
          <Modal open={showModal} toggle={() => setShowModal((p) => !p)}>
            <ModalHeader title="How it works" onClose={() => setShowModal((p) => !p)} />
            <ModalBodyContent>
              <ol className="list-decimal">
                <li className="mb-4">
                  Find qualifying stations nationwide by using the "AtoB Savings Stations" filter in
                  Fuel Map. Use your fuel card to receive an extra 10¢/gal in savings, on top of our
                  regular fuel discounts.
                </li>
                <li>
                  Your extra 10¢/gal savings will be added as a statement credit to your account
                  monthly. This offer applies to all eligible fuel transactions from now until
                  February 4th 2025!
                </li>
              </ol>
            </ModalBodyContent>
          </Modal>
        </div>
      )}
      <FormControl className="gap-2 px-6 pt-4 md:pt-0">
        <div className="space-y-2">
          <GoogleAutocomplete
            placesService={placesService}
            autocompleteValue={address}
            currentLocation={
              userLocation
                ? {
                    location_type: 'my_location',
                    place_id: 'current_location',
                    formatted_address: 'Current Location',
                    address_components: [],
                    description: 'Current Location',
                    structured_formatting: {
                      main_text: 'Current Location',
                      secondary_text: '',
                    },
                    geometry: {
                      location: {
                        lat: () => userLocation.latitude,
                        lng: () => userLocation.longitude,
                      },
                    },
                  }
                : undefined
            }
            selectPlace={(
              place: google.maps.places.PlaceResult | MyLocation,
              autocompleteAddress,
            ) => {
              const latitude = place?.geometry?.location?.lat();
              const longitude = place?.geometry?.location?.lng();

              if (place.place_id === 'current_location') {
                setOriginAddressIsEligibleForMarker(true);
              } else {
                setOriginAddressIsEligibleForMarker(
                  place?.address_components?.some((component) =>
                    component.types.some((sub_component) => sub_component === 'street_number'),
                  ) ?? false,
                );
              }
              setAddress(autocompleteAddress);

              if (!latitude || !longitude) {
                setAddressCoords(undefined);
                setMapCenterCoords(undefined);
              } else {
                setShowMoreButton(false);
                setFitBounds(true);
                setAddressCoords({ latitude, longitude });
                setMapCenterCoords({ latitude, longitude });
              }

              setSelectedFuelListingId(null);
              navigate('/fuel-map');
            }}
            startAdornment={<OriginStartAdornments routing={routing} />}
            endAdornment={
              <OriginEndAdornments
                isDisabled={!placesService || isFetching}
                routing={routing}
                onCancelRoutingClick={() => {
                  setRouting(false);
                  setDestinationCoords(undefined);
                  setDestinationAddress(EMPTY_LOCATION);
                }}
                onRoutingClick={() => {
                  setRouting(true);
                  setRoutingDisplayRadius(null);
                }}
              />
            }
          />
          {routing && (
            <GoogleAutocomplete
              label="Destination"
              placesService={placesService}
              autocompleteValue={destinationAddress}
              selectPlace={(
                place: google.maps.places.PlaceResult | MyLocation,
                autocompleteAddress,
              ) => {
                const latitude = place?.geometry?.location?.lat();
                const longitude = place?.geometry?.location?.lng();

                if (!latitude || !longitude) {
                  setDestinationCoords(undefined);
                } else {
                  setDestinationCoords({ latitude, longitude });
                  setDestinationAddress(autocompleteAddress);
                }
              }}
              startAdornment={<OriginStartAdornments routing={routing} destination />}
              endAdornment={
                <OriginEndAdornments
                  isDisabled={!placesService || isFetching}
                  routing={routing}
                  destination
                  onCancelRoutingClick={() => {
                    const oldAddress = { ...address };
                    const oldAddressCoords =
                      addressCoords != null ? { ...addressCoords } : undefined;
                    const oldDestination = { ...destinationAddress };
                    const oldDestinationCoords =
                      destinationCoords != null ? { ...destinationCoords } : undefined;
                    setDestinationCoords(oldAddressCoords);
                    setDestinationAddress(oldAddress);
                    setAddress(oldDestination);
                    setAddressCoords(oldDestinationCoords);
                  }}
                  onRoutingClick={() => {
                    setRouting(true);
                    setRoutingDisplayRadius(null);
                  }}
                />
              }
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex w-full flex-col gap-2 md:flex-row">
            {!disableDiscountNetworkSelect && (
              <DiscountNetworkSelect
                disabled={!placesService || isFetching || routing}
                selected={routing ? false : discountOnly}
                onSelectDiscountNetwork={setDiscountOnly}
              />
            )}
            {/* <BrandFilterSelect
                      onDiscountToggle={setDiscountOnly}
                      discountOnly={discountOnly}
                      onBrandsSelect={setBrandIDs}
                      selectedBrandIDs={brandIDs}
                      isDisabled={!placesService || isFetching}
                    /> */}
            <GradeSelect
              disabled={!placesService || isFetching}
              selectedGrade={selectedGrade}
              onSelectGrade={onSelectGrade}
            />
            {!routing && (
              <RadiusSelect
                disabled={!placesService || isFetching || address.place_id === 'map_location'}
                selectedRadius={selectedRadius}
                onSelectRadius={onSelectRadius}
              />
            )}
          </div>
          <div className="flex w-full justify-between">
            <div>
              {!isFetching ? (
                <>
                  <p className="text-sm font-medium">Results</p>
                  <p className="text-xs font-normal">
                    {filteredFuelData.length} {routing ? 'Cheapest ' : ''} Merchants
                  </p>
                </>
              ) : (
                <>
                  <Skeleton className="w-16 text-sm" />
                  <Skeleton className="w-20 text-xs" />
                </>
              )}
            </div>
            <div className="flex">
              <SortSelect
                disabled={!placesService || isFetching || routing}
                selectedSort={routing ? 'discountedPrice' : selectedSort}
                onSelectSort={setSelectedSort}
              />
            </div>
          </div>
        </div>
      </FormControl>
      {challengeMatch && <DiscountBoostToggle />}
      {costPlusDriverCashback && !challengeMatch && <DriverRewardedStationsToggle />}
      <FuelListingsList
        isFetching={isFetching}
        originLocation={addressCoords}
        fuelListings={filteredFuelData}
        selectedGrade={selectedGrade}
        onSelect={onSelectFuelListing}
        showMore={
          routing
            ? () => {
                setFitBounds(true);
                setShowMoreButton(false);
                let newRadius: number = 0;
                if (routing && addressCoords != null) {
                  newRadius = (routingDisplayRadius || defaultRouteRadius || 0) / 2;
                }
                setRoutingDisplayRadius(newRadius);
              }
            : null
        }
      />
    </div>
  );
};

export default FuelListings;
