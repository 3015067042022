// import ErrorMessage from '@atob-developers/shared/src/components/';
import ErrorMessage from '@atob-developers/shared/src/components/ErrorMessage';
import { ReactElement } from 'react';

const BankAccountDetailsVGSForm = ({
  error,
  validationError,
  displayConfirmation = false,
}: {
  error: string | null;
  validationError: string | null;
  displayConfirmation: boolean;
}): ReactElement => {
  return (
    <>
      <div className="mb-4 w-full flex-col" id="vgs-wrapper">
        {error && <ErrorMessage error={error} />}
        {validationError && <ErrorMessage error={validationError} />}
        {!error && (
          <>
            <div className="mb-2 w-full">
              <label htmlFor="vgs-routing-number" className="row-span-full text-sm font-medium">
                Routing Number *
              </label>
              <div className="h-10 w-full" id="vgs-routing-number"></div>
            </div>
            <div className="mb-2 w-full">
              <label htmlFor="vgs-account-number" className="row-span-full text-sm font-medium">
                Account Number *
              </label>
              <div className="h-10 w-full" id="vgs-account-number"></div>
            </div>
            <div className="w-full" hidden={!displayConfirmation}>
              <label
                htmlFor="vgs-confirm-account-number"
                className="row-span-full text-sm font-medium"
              >
                Confirm Account Number *
              </label>
              <div className="h-10 w-full" id="vgs-confirm-account-number"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BankAccountDetailsVGSForm;
