import { Spacer } from '@app/components/elements';
import { faCloudXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { getStripeLink } from './Onboarding';

export default function WalletLinkExpired(): ReactElement {
  const continueToStripe = async () => {
    const stripeLink = await getStripeLink();
    window.location.href = stripeLink;
  };

  return (
    <div className="h-full w-full">
      <Spacer />
      <div className="flex h-64 w-full flex-col items-center justify-center">
        <div className="container rounded-lg border-[1px] border-red-100 bg-red-50 p-4 md:w-[436px]">
          <div className="flex flex-col items-center">
            <FontAwesomeIcon className="h-[40px] w-[50px] text-red-600" icon={faCloudXmark} />
            <p className="text-center text-base font-bold text-red-600">
              Your onboarding link has expired.
            </p>
            <p className="text-center text-base font-normal">
              Please click the button below to continue.
            </p>
          </div>
        </div>
        <div className="mt-4 min-w-[200px]">
          <Button color="secondary" onClick={() => continueToStripe()}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
