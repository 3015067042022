import { ReactElement } from 'react';

export const SuggestedAmounts = ({
  setAmount,
}: {
  setAmount: (amount: string) => void;
}): ReactElement => {
  const suggestedButtonClass = `text-atob-green bg-white border border-gray-200 px-2 py-1 rounded-md text-base md:text-sm font-semibold`;
  return (
    <div className="mb-4 flex w-[220px] justify-between gap-2 md:w-[190px]">
      <button type="button" onClick={() => setAmount('100.00')} className={suggestedButtonClass}>
        $100
      </button>
      <button type="button" onClick={() => setAmount('250.00')} className={suggestedButtonClass}>
        $250
      </button>
      <button type="button" onClick={() => setAmount('500.00')} className={suggestedButtonClass}>
        $500
      </button>
    </div>
  );
};
