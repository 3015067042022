import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import { ModalOptions } from '@app/components/Modals/ModalOptions/ModalOptions';
import { SecurityDepositOfferDetails } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferDetails';
import { SecurityDeposit } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferModal';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useSecurityDeposit from '@app/hooks/useSecurityDeposit';
import { getFetcher } from '@app/utils/data/fetchers';
import Modal from '@atob-developers/shared/src/components/Modal';
import { faCircleInfo, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Slider } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import useSWR from 'swr';
import { useOnboardingGuide } from './useOnboardingGuide';

type SecurityDepositPendingProps = {
  securityDeposit: SecurityDeposit;
};

function SecurityDepositPending({ securityDeposit }: SecurityDepositPendingProps) {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="max-w-screen-sm p-8">
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-8">
            <FontAwesomeIcon className="fa-2x" icon={faClock} />
          </div>
          <h1 className="mb-2 text-center text-2xl font-bold">Thank you!</h1>
          <h2 className="mb-8 text-center text-lg">Your security deposit payment is pending.</h2>
        </div>

        <div className="mb-6 mt-6 rounded-lg bg-gray-100 p-4 outline outline-1 outline-gray-200">
          <div className="mb-4 flex justify-between">
            <div className="text-gray-500">Credit Limit</div>
            <div className="font-bold">${securityDeposit.credit_limit_cents / 100}</div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-500">Security Deposit</div>
            <div className="font-bold">${securityDeposit.amount_cents / 100}</div>
          </div>
        </div>

        <div className="mt-6 rounded-lg bg-gray-100 p-4">
          <div className="flex justify-between">
            <FontAwesomeIcon className="fa-lg p-4" icon={faCircleInfo} />
            <p className="text-md w-full px-4 font-medium">
              {securityDeposit.payment_method_type === 'ach'
                ? 'Your credit line will be activated once your payment is successful. This generally takes 2-3 business days. We will email you when the process succeeds.'
                : 'Your credit line will be activated once your payment is successful. This should only takes a few minutes and we will email you when the process succeeds.'}
            </p>
          </div>
        </div>
        <Button fullWidth className="mt-4" color="secondary" size="medium" onClick={goToNextStep}>
          Done
        </Button>
      </div>
    </div>
  );
}

type SecurityDepositContentProps = {
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
};

function SecurityDepositContent({
  offerDetails,
  refetchOfferDetails,
}: SecurityDepositContentProps) {
  const { goToNextStep } = useOnboardingGuide();

  const {
    securityDepositModalOpen,
    setSecurityDepositModalOpen,
    fixedOffer,
    desiredCreditLine,
    setDesiredCreditLine,
    options,
    selectedOption,
    setSelectedOption,
    isLoading,
    handleContinue,
  } = useSecurityDeposit({
    offerDetails,
    refetchOfferDetails,
  });

  return (
    <>
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={offerDetails.existing_deposit || null}
          onClose={() => {
            setSecurityDepositModalOpen(false);
          }}
          continueOnboarding={goToNextStep}
        />
      </Modal>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="max-w-screen-sm p-8">
          <h1 className="text-2xl font-semibold tracking-tight">Activate your Credit Line</h1>
          <div className="py-4">
            <h2 className="mb-2">
              {offerDetails.offer_open
                ? fixedOffer
                  ? 'See your offer details below'
                  : 'Select your desired credit limit below'
                : 'Your cards have been shipped. Pay the deposit to activate your credit line.'}
            </h2>
            {!fixedOffer && (
              <Slider
                className="mb-4"
                value={desiredCreditLine}
                onChange={(_, value) => setDesiredCreditLine(value as number)}
                marks={[
                  { value: 500, label: '$500' }, // add extra mark at $500
                  ...Array.from(
                    { length: (offerDetails.max_credit_limit || 4000) / 1000 + 1 },
                    (_, index) => ({
                      value: index * 1000,
                      label: `$${index * 1000}`,
                    }),
                  ),
                ]}
                min={0}
                max={offerDetails.max_credit_limit || 4000}
                step={null}
                valueLabelFormat={(value) => `$${value}`}
              />
            )}
            <div className="mt-4 flex flex-col text-base text-gray-700">
              <ModalOptions
                options={options}
                selectedOptionId={selectedOption}
                setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
              />
            </div>
          </div>
          <LoadingButton
            fullWidth
            loading={isLoading}
            className="flex-1"
            color="primary"
            disabled={desiredCreditLine === 0}
            onClick={handleContinue}
          >
            {offerDetails.offer_open ? 'Accept offer and ship my cards!' : 'Continue'}
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default function OnboardingSecurityDeposit() {
  const [SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN, SECURITY_DEPOSIT_NET_NEW_ENROLLED] = useFeatureFlags(
    'security_deposit_net_new_offer_open',
    'security_deposit_net_new_enrolled',
  );
  const { goToNextStep } = useOnboardingGuide();
  const { interstitials } = useCustomer();

  const isSecurityDepositCustomer =
    SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN || SECURITY_DEPOSIT_NET_NEW_ENROLLED;

  const {
    data: offerDetails,
    isLoading,
    mutate: refetchOfferDetails,
  } = useSWR<SecurityDepositNetNewOfferDetails>(
    isSecurityDepositCustomer ? { url: `/security_deposits/net_new_offer_details` } : null,
    getFetcher,
  );

  useEffect(() => {
    if (
      [
        'deposit_paid',
        'fully_refunded',
        'partially_refunded',
        'closed',
        'deposit_pending',
        'deposit_paid_pending_hold',
      ].includes(offerDetails?.existing_deposit?.status || '')
    ) {
      goToNextStep();
    }
  }, [offerDetails?.existing_deposit?.status, goToNextStep]);

  if (!isSecurityDepositCustomer || interstitials == null) {
    return null;
  }

  if (isLoading || !offerDetails) {
    return <SpinnerBoundary />;
  }

  if (
    offerDetails.existing_deposit?.status === 'deposit_pending' ||
    offerDetails.existing_deposit?.status === 'deposit_paid_pending_hold'
  ) {
    return <SecurityDepositPending securityDeposit={offerDetails.existing_deposit} />;
  }

  return (
    <SecurityDepositContent offerDetails={offerDetails} refetchOfferDetails={refetchOfferDetails} />
  );
}
