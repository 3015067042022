import { Button, useMediaQuery, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarketingPopup from './components/MarketingPopup';

const SavingsPoint = ({ point, subpoint }: { point: string; subpoint: string }): JSX.Element => {
  return (
    <>
      <div className="mb-1 text-base font-semibold text-gray-900">{point}</div>
      <div className="mb-4 text-sm font-normal">{subpoint}</div>
    </>
  );
};

const AtoBSavingsMarketingPopup = (): JSX.Element | null => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <MarketingPopup
      storageKey="atob_savings_popup"
      heading=""
      headerImg={isNotMobile ? '/images/atob-savings.png' : '/images/atob-savings-mobile.png'}
      body={
        <>
          <SavingsPoint
            point="1. See your savings performance over time"
            subpoint="At a glance, see how much you have saved"
          />
          <SavingsPoint
            point="2. Where you saved, where you missed"
            subpoint="Now, we'll show you if there was an opportunity nearby for you to save more"
          />
          <SavingsPoint
            point="3. Use the fuel map to see the best discounts"
            subpoint="See the best discounts nearby or along your route"
          />
        </>
      }
      footer={(dismiss) => (
        <div className="flex flex-row items-center gap-4">
          {isNotMobile && (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                dismiss();
              }}
            >
              Dismiss
            </Link>
          )}
          <Button
            size="small"
            className="mx-auto w-full"
            onClick={() => {
              navigate('/fuel-map');
              dismiss();
            }}
          >
            View Savings Nearby
          </Button>
        </div>
      )}
    />
  );
};

export default AtoBSavingsMarketingPopup;
