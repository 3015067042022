import { ReactElement } from 'react';

const TermsOfServiceHeader = (): ReactElement => {
  return (
    <div
      className="m-5 flex w-full flex-col md:flex md:items-center md:text-left"
      style={{ paddingTop: '0px', marginTop: '0px' }}
    >
      <div className="md:w-[500px]">
        <h3 className="my-6 text-3xl font-bold text-gray-900" style={{ marginTop: '0px' }}>
          Terms of Service
        </h3>
        <div className="mb-4 text-sm font-normal">
          By clicking Accept, you agree to the below documents and to receiving automated text
          messages from AtoB, along with the following:
        </div>
        <div className="mx-8 text-sm">
          <ul className="list-disc space-y-1">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/ssa#services-terms"
              >
                Stripe Issuing Accountholder Terms
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/celtic-charge-card"
              >
                Issuing Bank Accountholder Terms: Charge Card
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/issuing-offer-document-fees"
              >
                Commercial financing disclosure, California cardholder only, fee charged
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/connect-account"
              >
                Stripe Connected Account Agreement
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://staic-files-hosting.s3.us-west-1.amazonaws.com/AtoB+Platform+Agreement+Latest.pdf"
              >
                AtoB Platform Agreement
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServiceHeader;
