import { Drawer } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { TransferKind } from '../utils';
import TransferDetailsForm from './TransferDetailsForm';
import TransferTypeSelector from './TransferTypeSelector';
import { WalletTransferContext, WalletTransferStep } from './useWalletTransfer';

export const WithdrawFundsSidebarNew = ({
  open,
  reset,
  onCompletedTransfer,
  walletBalance,
}: {
  open: boolean;
  reset: () => void;
  onCompletedTransfer: () => void;
  walletBalance?: string | null;
}) => {
  const [transferKind, setTransferKind] = useState<TransferKind | null>(null);
  const [step, setStep] = useState<WalletTransferStep>(WalletTransferStep.TransferType);

  const resetAndClose = useCallback(() => {
    setTransferKind(null);
    setStep(WalletTransferStep.TransferType);

    reset();
  }, [reset]);

  const contextVal = useMemo(
    () => ({
      step,
      transferKind,
      setTransferKind,
      goNextStep: (s?: WalletTransferStep) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p + 1;
          }
        }),
      goPrevStep: (s?: WalletTransferStep) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p - 1;
          }
        }),
      resetAndClose,
      onCompletedTransfer,
      walletBalance,
    }),
    [onCompletedTransfer, resetAndClose, step, transferKind, walletBalance],
  );

  return (
    <WalletTransferContext.Provider value={contextVal}>
      <Drawer open={open} onClose={resetAndClose} anchor="right">
        <div className="flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
          {step === WalletTransferStep.TransferType && <TransferTypeSelector />}
          {/* {step === WalletTransferStep.Recipient && <ReceipientSelector />} */}
          {step === WalletTransferStep.TransferDetails && <TransferDetailsForm />}
        </div>
      </Drawer>
    </WalletTransferContext.Provider>
  );
};
