import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useNavigate } from 'react-router-dom';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function NetNewCp8Banner() {
  const [showBanner] = useFeatureFlags('new_cx_discount_promo');
  const nav = useNavigate();

  if (!showBanner) {
    return null;
  }

  return (
    <MarketingBanner
      onClick={() => nav('/fuel-map')}
      actionText="Find Fuel"
      bannerKey="net_new_cp8_banner"
    >
      <strong>Limited-time offer:</strong> Save even more at stations in AtoB's discount network
      with up to{' '}
      <strong>
        12¢/gal in <em>extra savings</em>
      </strong>{' '}
      on top of our average discounts of 46¢/gal. Find in-network stations with Fuel Map
    </MarketingBanner>
  );
}
