import LineItem from '@app/components/layout/LineItem';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';

export const TransferSummary = ({
  recipientName,
  feePercentage,
  amount,
  feeAmount,
  description,
  transferType,
  arrivalTime,
}: {
  recipientName: string | null;
  feePercentage: number;
  feeAmount: number;
  description: string;
  amount: number;
  transferType: string;
  arrivalTime: string;
}) => {
  return (
    <div className="bg-background border-level-2 flex flex-col gap-3 rounded-lg border p-4 text-sm">
      {recipientName && <LineItem label="Recipient" value={recipientName} />}
      <LineItem label="Transfer type" value={transferType} />
      <LineItem
        label="Amount"
        value={formatCurrency({ value: amount, options: { fromCents: true } })}
      />
      <LineItem
        label={`Fee (${feePercentage}%)`}
        value={formatCurrency({ value: feeAmount, options: { fromCents: true } })}
      />
      <LineItem
        label={'Total Amount'}
        value={formatCurrency({ value: amount + feeAmount, options: { fromCents: true } })}
      />
      <LineItem label="Est. Arrival Time" value={arrivalTime} />
      {description ? (
        <div>
          <span className="text-secondary">Description</span>
          <p className="pt-2 font-semibold">{description}</p>
        </div>
      ) : (
        <div className="flex justify-between">
          <span className="text-secondary">Description</span>
          <p className="text-secondary">Blank</p>
        </div>
      )}
    </div>
  );
};
