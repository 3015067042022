import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { RepaymentSummary } from './RepaymentSummary';

export const SuccessModal = ({
  open,
  onClose,
  recipientName,
  amount,
  description,
  referenceId,
}: {
  open: boolean;
  onClose: () => void;
  amount: string;
  description: string;
  recipientName: string;
  referenceId: string;
}) => {
  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader
        title="Repayment complete"
        secondaryTitle="The funds were successfully submitted to Fleet Logistics."
        className="text-center"
        headerImage={
          <FontAwesomeIcon
            icon={faCheck}
            className="bg-accent-12 text-accent-10 flex h-5 w-5 justify-self-center rounded-full p-4"
          />
        }
      />
      <ModalBodyContent>
        <div className="mb-4 mt-2 flex flex-col gap-6">
          <RepaymentSummary
            amount={amount}
            referenceId={referenceId}
            recipientName={recipientName}
            description={description}
          />
          <div className="flex gap-3">
            <Button fullWidth onClick={onClose} className="p-3">
              Back to Balance Repayment
            </Button>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
