import { PaginationMeta } from '@app/@types/api.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import { GoToPageAction } from '@app/hooks/paging/types';
import { ReactElement } from 'react';
import { Repayment } from './BalanceRepayment';
import RepaymentTable from './RepaymentTable';

export default function Repayments({
  repaymentsPage,
  repaymentsError,
  metadata,
  goToPage,
  loading,
}: {
  repaymentsPage: Repayment[];
  repaymentsError: unknown;
  metadata: PaginationMeta | undefined;
  goToPage: GoToPageAction;
  loading: boolean;
}) {
  if (repaymentsError) {
    return (
      <ErrorNotification error="We're having issues loading your repayments data. Please try again; if the issue persists, please contact support." />
    );
  }

  return (
    <RoundedCard>
      <p className="text-primary px-6 py-4 font-semibold">Repayments for all carriers</p>
      <div className="border-level-2 border-b" />
      {metadata == null || metadata?.pagination.total_count === 0 || repaymentsPage.length === 0 ? (
        <NoRepayments />
      ) : (
        <RepaymentTable
          onPageIndexChange={(pageIndex: number) => goToPage(pageIndex)}
          metadata={metadata}
          repaymentPage={repaymentsPage}
          loading={loading}
        />
      )}
    </RoundedCard>
  );
}

const NoRepayments = (): ReactElement => {
  return (
    <div className="flex h-[432px] w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/card.svg" alt="Card" />
      <div className="mt-4 flex max-w-[350px] flex-col items-center text-center">
        <h2 className="text-primary mb-3 text-2xl font-semibold">No repayments found</h2>
        <p className="text-secondary text-lg">
          Repayments will appear here once you submit the first one.
        </p>
      </div>
    </div>
  );
};
