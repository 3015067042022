import { DetailElement } from '@app/pages/TransactionDetail/DetailSection';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

type TransactionReceiptModalProps = {
  merchant: string;
  transaction: string;
  destination: string;
  amount: string;
  datePosted: string;
  description: string;
  receiptUrl: string;
  showTransactionReceiptModal: boolean;
  modalToggle: () => void;
};

function CustomDetailElement({
  name,
  value,
  wrap = false,
}: {
  name: string;
  value: string;
  wrap?: boolean;
}): ReactElement {
  return (
    <DetailElement
      wrap={wrap}
      name={name}
      value={value}
      nameClassName="text-secondary text-base whitespace-nowrap md:basis-1/3"
      valueClassName={classNames(
        'md:justify-end text-primary text-base font-semibold md:basis-2/3',
        !wrap && 'overflow-hidden whitespace-nowrap',
      )}
    />
  );
}

const alertMessage = (
  <>
    <div className="mb-3">
      You are getting this receipt because you conducted a transaction with{' '}
      <a
        href="https://stripe.com/en-pl/legal/spc/licenses"
        className="text-primary font-medium underline"
        target="_blank"
        rel="noreferrer"
      >
        Stripe Payments Company
      </a>{' '}
      located at 354 Oyster Point Boulevard, South San Francisco, CA 94080. Contact by telephone:
      <a href="tel:+18889262289"> +1 888-926-2889</a>
    </div>
    <a
      href="https://stripe.com/en-pl/legal/spc/licenses"
      className="text-primary text-sm font-medium underline"
      target="_blank"
      rel="noreferrer"
    >
      View the consumer disclosure and rights notice.
    </a>
  </>
);

const TransactionReceiptModal = ({
  merchant,
  transaction,
  destination,
  amount,
  datePosted,
  description,
  receiptUrl,
  showTransactionReceiptModal,
  modalToggle,
}: TransactionReceiptModalProps): ReactElement => {
  const displayDestination = (data: string | null): string => {
    if (!data) return 'Wallet';

    const shouldMask = !isNaN(+data) && data.length > 4;
    return shouldMask ? `** ${data.slice(-4)}` : data;
  };

  return (
    <Modal maxWidth="xs" open={showTransactionReceiptModal} toggle={modalToggle}>
      <div className="flex justify-start">
        <ModalHeader title="Transaction Receipt" onClose={modalToggle} />
      </div>
      <ModalBodyContent>
        <div className="flex flex-col gap-6 pb-3">
          <div className="mt-4">
            <div className="text-primary mb-4 text-lg font-semibold">Details</div>
            <div className="bg-background border-level-2 rounded-md border pb-1 pt-1">
              <CustomDetailElement name="Merchant" value={merchant} />
              <CustomDetailElement name="Transaction" value={transaction} />
              <CustomDetailElement name="Destination" value={displayDestination(destination)} />
              <CustomDetailElement name="Amount Received" value={amount} />
              <CustomDetailElement name="Date Posted" value={datePosted} />
              <CustomDetailElement name="Description" value={description} wrap />
            </div>
          </div>
          <Alert severity="info" title="Disclosure" content={alertMessage} />
          <a href={receiptUrl}>
            <Button className="w-full p-3">View receipt</Button>
          </a>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};

export default TransactionReceiptModal;
