import { FieldState } from '@app/components/VGSElements/types';
import { cssWithWidth } from '@app/components/VGSElements/utils';
import AddressInput, { Address } from '@app/components/elements/AddressInput';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { getEnvironment } from '@app/utils/environment';
import isNumeric from '@app/utils/is-numeric';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { ReactElement, Dispatch, useCallback, useState, useRef, useEffect } from 'react';
import BankAccountDetailsVGSForm from './BankAccountDetailsVGSForm';
import './RecipientForm.css';

type RecipientFormProps = {
  type: 'add' | 'edit';
  formId: string;
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    tokenizedRoutingNumber?: string,
    tokenizedAccountNumber?: string,
  ) => void;
  errors: Record<string, string>;
  name: string;
  setName: (name: string) => void;
  nickname: string;
  setNickname: (nickname: string) => void;
  isBusinessAccount: boolean;
  setIsBusinessAccount: (isBusinessAccount: boolean) => void;
  routingNumber: string;
  setRoutingNumber: (routingNumber: string) => void;
  accountNumber: string;
  setAccountNumber: (accountNumber: string) => void;
  confirmedAccountNumber: string;
  setConfirmedAccountNumber: (confirmedAccountNumber: string) => void;
  addressValues: Address;
  setAddressValues: (addressValues: Address) => void;
  addressErrors: Record<string, string>;
  setAddressErrors: (addressErrors: Record<string, string | undefined>) => void;
  touched: Record<string, boolean>;
  setTouched: (values: Record<string, boolean | undefined>) => void;
  children: React.ReactNode;
  vgs: boolean;
  setValidAccountNumbers: (validAccountNumbers: boolean) => void;
};

export default function RecipientForm({
  type,
  formId,
  onSubmit,
  errors,
  name,
  setName,
  nickname,
  setNickname,
  isBusinessAccount,
  setIsBusinessAccount,
  routingNumber,
  setRoutingNumber,
  accountNumber,
  setAccountNumber,
  confirmedAccountNumber,
  setConfirmedAccountNumber,
  addressValues,
  setAddressValues,
  addressErrors,
  setAddressErrors,
  touched,
  setTouched,
  children,
  vgs,
  setValidAccountNumbers,
}: RecipientFormProps): ReactElement {
  const { isMobile } = useWindowWidth();
  const { VITE_VGS_BANK_INFO_VAULT_ID, VITE_VGS_ENVIRONMENT, VITE_VGS_TOKENIZATION_ROUTE } =
    getEnvironment();
  const [form, setForm] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [vgsError, setVgsError] = useState('');

  const initForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vgsCollect: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = vgsCollect.init((_: any) => {});
      form.setRouteId(VITE_VGS_TOKENIZATION_ROUTE);
      const routingNumberField = form.field('#vgs-routing-number', {
        type: 'text',
        name: 'routingNumber',
        placeholder: 'Routing Number',
        validations: ['required'],
        css: cssWithWidth('100%'),
      });
      const accountNumberField = form.field('#vgs-account-number', {
        type: 'text',
        name: 'accountNumber',
        placeholder: 'Account Number',
        validations: ['required'],
        css: cssWithWidth('100%'),
      });
      const confirmAccountNumberField = form.field('#vgs-confirm-account-number', {
        type: 'text',
        name: 'confirmAccountNumber',
        placeholder: 'Confirm Account Number',
        validations: [
          'required',
          {
            type: 'compareValue',
            params: {
              field: 'accountNumber',
              function: 'match',
            },
          },
        ],
        css: cssWithWidth('100%'),
      });

      [routingNumberField, accountNumberField, confirmAccountNumberField].forEach((field) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        field.on('update', (_: any) => {
          setValidAccountNumbers(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            (Object.values(form?.state) as FieldState[]).reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: any, curr: FieldState) => acc && curr?.isValid,
              true,
            ),
          );
        });
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accountNumberField.on('update', (fieldState: any) => {
        setDisplayConfirmation(fieldState.isValid);
      });
      setForm(form);
    },
    [VITE_VGS_TOKENIZATION_ROUTE, setValidAccountNumbers],
  );

  const hasLoadedForm = useRef(false);
  useEffect(() => {
    const loadForm = async () => {
      try {
        const vgsCollect = await loadVGSCollect({
          vaultId: VITE_VGS_BANK_INFO_VAULT_ID as string,
          environment: VITE_VGS_ENVIRONMENT as string,
          version: '2.18.1',
        });
        initForm(vgsCollect);
      } catch (e: unknown) {
        setVgsError(
          'We were unable to load the banking information form. Please try again. If the error persists, please contact our support team.',
        );
        Sentry.captureMessage('Error setting up VGS Collect');
      }
    };

    if (!hasLoadedForm.current && vgs) {
      hasLoadedForm.current = true;
      loadForm();
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form.tokenize(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (_: any, response: any) {
        setRoutingNumber(response['routingNumber']);
        setAccountNumber(response['accountNumber']);
        onSubmit(e, response['routingNumber'], response['accountNumber']);
      },
      () => {},
    );
  };

  return (
    <form id={formId} onSubmit={vgs ? handleSubmit : onSubmit}>
      <FormElement
        required
        disabled={type === 'edit'}
        error={errors.name}
        element={{
          label: 'Account Holder Name',
          type: DataItemType.TEXT,
          key: 'name',
        }}
        handleOnChange={(value) => value !== null && setName(value)}
        value={name}
      />
      <FormElement
        element={{
          label: 'Nickname (optional)',
          placeholder: 'Nickname',
          type: DataItemType.TEXT,
          key: 'nickname',
        }}
        handleOnChange={(value) => value !== null && setNickname(value)}
        value={nickname}
      />
      <div className="mt-2 max-w-[200px]">
        <div className="mb-1 text-sm font-medium text-gray-700">Account Type</div>
        <RadioGroup
          data={[
            {
              label: 'Business',
              name: 'Business',
              value: 'business',
              id: 'business',
              checked: isBusinessAccount,
            },
            {
              label: 'Personal',
              name: 'Personal',
              value: 'personal',
              id: 'personal',
              checked: !isBusinessAccount,
            },
          ]}
          onSelect={(id) => {
            if (type === 'edit') {
              return;
            }
            setIsBusinessAccount(id === 'business');
          }}
          className="flex justify-between"
        />
      </div>
      {type === 'add' && !vgs && (
        <>
          <FormElement
            required
            error={errors.routing}
            element={{
              label: 'Routing Number',
              type: DataItemType.TEXT,
              key: 'routing',
            }}
            noPaste
            handleOnChange={(value) => {
              if (value && !isNumeric(value)) {
                return;
              }
              value !== null && setRoutingNumber(value);
            }}
            value={routingNumber}
          />
          <FormElement
            required
            error={errors.account}
            element={{
              label: 'Account Number',
              type: DataItemType.TEXT,
              key: 'account',
            }}
            noPaste
            handleOnChange={(value) => {
              if (value && !isNumeric(value)) {
                return;
              }
              value !== null && setAccountNumber(value);
            }}
            value={accountNumber}
          />
        </>
      )}
      {!vgs && (accountNumber !== '' || confirmedAccountNumber !== '') && (
        <FormElement
          required
          error={errors.confirmedAccount}
          element={{
            label: 'Confirm Account Number',
            type: DataItemType.TEXT,
            key: 'confirmAccount',
          }}
          noPaste
          handleOnChange={(value) => {
            if (value && !isNumeric(value)) {
              return;
            }
            value !== null && setConfirmedAccountNumber(value);
          }}
          value={confirmedAccountNumber}
        />
      )}
      {type === 'add' && vgs && (
        <BankAccountDetailsVGSForm
          error={vgsError}
          validationError={errors.confirmedAccount}
          displayConfirmation={displayConfirmation}
        />
      )}
      <AddressInput
        isMobile={isMobile}
        label="Recipient Address (for Wire Transfers only)"
        addressValues={addressValues as Record<string, string>}
        setAddressValues={setAddressValues as Dispatch<unknown>}
        errors={addressErrors}
        setErrors={setAddressErrors}
        touched={touched}
        setTouched={setTouched}
      />
      <div className="mb-4 mt-8 flex w-full justify-end">{children}</div>
    </form>
  );
}
